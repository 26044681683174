import React, { useState, useEffect, useRef } from 'react';

import ImageNoAvailable from '../../../images/Image_not_available.png';
import ImagePlaceholder from './placeholders';

import type { Image as ImageProps } from './image.types';

import {
  MainImage, PlaceHolder, PictureWrapper
} from './image.styles';

/**
 *
 * @param {string} src Default url for the image.
 * @param {string} title Image alternative text.
 * @param {string} [src2x] Default url for display 2x pixels.
 * @param {import('./image.types').Source[]} [sources] Additional images sizes based on media queries.
 * @param {string} [imageWidth] Width property to be added to the <img>.
 * @param {string} [imageHeight] Height property to be added to the <img>.
 * @param {import('./image.types').LoadingType} [loading='lazy'] Loading of image ('lazy' | 'eager').
 * @param {import('./image.types').ObjectFit} [fit='cover'] How to fit the image into the container.
 * @param {string} [positionX='center'] How to position the image on the X axis.
 * @param {string} [positionY='center'] How to position the image on the Y axis.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading
 *
 */

export const Image = ({ src, title, src2x, sources, imageWidth, imageHeight, loading = 'lazy', fit = 'cover', positionX = 'center', positionY = 'center', testid = 'image', parentIsLoading = false }:ImageProps):React.ReactElement => {
  const [isLoaded, setIsLoaded] = useState(false);
  const hasImage = !!src;
  const imageSrc = hasImage ? src : ImageNoAvailable;
  const imageTitle = hasImage ? title : 'Image not found';

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setIsLoaded(true);
    }
  }, [imageRef]);

  const getDimension = (dim?:string):string => {
    if(!dim) return '';
    const fullDimension = dim.replace('px', '');
    return fullDimension;
  };

  if (parentIsLoading) {
    return (
      <PlaceHolder data-testid={`${testid}-data-loading`} imageWidth={Number(getDimension(imageWidth))} imageHeight={Number(getDimension(imageHeight))}>
        <ImagePlaceholder width={getDimension(imageWidth)} height={getDimension(imageHeight)} />
      </PlaceHolder>
    );
  }

  return (
    <PlaceHolder data-testid={hasImage ? testid : `${testid}-not-found`} imageWidth={Number(getDimension(imageWidth))} imageHeight={Number(getDimension(imageHeight))}>
      <>
        {!isLoaded && (
          <ImagePlaceholder width={getDimension(imageWidth)} height={getDimension(imageHeight)} />
        )}
        <PictureWrapper
          loaded={isLoaded}
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsLoaded(false)}
        >
          {hasImage && sources?.map(source => {
            const source2x = source.src2x ? `, ${source.src2x} 2x` : '';
            return (
              <source key={`${source.src}-${source.minWidth}`} srcSet={`${source.src}${source2x}`} media={`(min-width: ${source.minWidth})`} />
            );
          })}
          <MainImage
            decoding='async'
            loading={loading}
            {...(hasImage && src2x ? { srcSet: `${src}, ${src2x} 2x` } : {})}
            src={imageSrc}
            alt={imageTitle}
            width={imageWidth}
            {...(imageHeight ? { height: imageHeight } : {})}
            objectFit={hasImage ? fit : 'contain'}
            positionX={positionX}
            positionY={positionY}
            ref={imageRef}
          />
        </PictureWrapper>
      </>
    </PlaceHolder>
  );
};
