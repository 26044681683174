import React from 'react';
import type { Button as ButtonProps } from './button.types';

import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import { ButtonWrapper } from './button.styles';

/**
* Render a `<button></button>` element
*
* @param {import('./button.types').ButtonSize} [size='large'] Size of the button ('large' | 'compact')
* @param {import('./button.types').ButtonType} [btnType='filled'] Button variant ('filled' | 'outlined')
* @param {React.ReactNode} children Element inside button
* @param {(event:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void} onClick Button callback
* @param {boolean} [responsive=false] Makes button responsive
* @extends {React.ComponentPropsWithoutRef<'button'>}
*/

export const Button = ({ size = 'large', btnType = 'filled', children, onClick, responsive = false, testid = 'trigger', id, ...nativeProps }: ButtonProps): React.ReactElement => (
  <ButtonWrapper
    {...(id ? { id } : {})}
    btnType={btnType}
    responsive={responsive}
    size={size}
    onClick={onClick}
    onKeyDown={(e:React.KeyboardEvent<HTMLButtonElement>) => executeOnKeyDown(e) && onClick && onClick(e)}
    data-testid={`button-${testid}`}
    {...nativeProps}
  >
    {children}
  </ButtonWrapper>
);
