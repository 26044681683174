import React from 'react';

import type { BasketItem as BasketItemProps } from './basketItem.types';

import { NumberPicker, Title, ProductImage, Icon, FreeGift } from '../../atoms';
import { CustomerOptions } from '../CustomerOptions';

import { timestampToDate } from '../../../utils';

import {
  BasketItemDescriptionPlaceholder,
  BasketItemDeliveryEstimatePlaceholder,
  BasketItemTotalEstimatePlaceholder,
  BasketItemPricePlaceholder,
  BasketItemIssuePricingPlaceholder,
  BasketItemRemovePlaceholder,
  SubscriptionTypePlaceholder
} from './placeholders/basketItemPlaceholders';

import {
  ItemWrapper,
  BasketItemWrapper,
  ImageWrapper,
  ContentWrapper,
  Description,
  DescriptionChildren,
  PriceInfo,
  DescriptionContent,
  RemoveItem,
  LinksWrappers,
  SubscriptionType,
  SubscriptionInfo,
  Total,
  TotalContent,
  SubscriptionContent,
  IssuePricing,
  ModifyContent,
  DeliveryContent,
  OriginalPrice,
  PriceContent
} from './basketItem.styles';

/**
 *
 * @param {string} title Provides a product title.
 * @param {string} description Provides a description.
 * @param {string} price Provides a price.
 * @param {{ type:string, baseUrl:string, path:string }} image Provides an image object.
 * @param {number} qty Provides a quantity to be added to basket.
 * @param {(_qty:number) => void} onClick Adds to basket with 'qty' count.
 * @param {boolean} [hideQty=false] Remove the qty selector.
 * @param {string} [subscriptionTitle] Extra subscription information.
 * @param {React.ReactNode} [children] Additional component to be added.
 * @param {boolean} [remove] Show the remove item btn.
 * @param {() => void} [onRemove] Remove btn event handler.
 * @param {{freeGiftIcon: string, freeGiftDescription: string}} promotion Any promotion attached to the item.
 * @param {string} [estimatedDeliveryDate] Estimated delivery time
 * @param {string} [originalPrice] Provides a strike-through price.
 * @param {boolean} [hasFollowOnPricing] Shows follow on price.
 * @param {string} [pricePerIssue] Shows price per issue.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading data
 *
 */

export const BasketItem = ({ title, description, price, image, qty, onClick, hideQty = false , subscriptionTitle, pricePerIssue, children, remove, testid = 'basket', onRemove, promotion, estimatedDeliveryDate, originalPrice, hasFollowOnPricing, parentIsLoading = false }: BasketItemProps): React.ReactElement => {

  const handleRemove = (e:React.MouseEvent<HTMLAnchorElement>):void => {
    e.preventDefault();
    if(onRemove) onRemove();
  };

  if (parentIsLoading) {
    return (
      <ItemWrapper>
        <BasketItemWrapper>
          <ImageWrapper>
            <ProductImage
              title={`${title} Cover`}
              imgWidth={75}
              variants={[]}
              parentIsLoading
            />
          </ImageWrapper>
          <ContentWrapper>
            <SubscriptionContent>
              <DescriptionContent>
                <SubscriptionInfo>
                  <Title text={title} tag='h5' parentIsLoading />
                  <SubscriptionType><SubscriptionTypePlaceholder /></SubscriptionType>
                </SubscriptionInfo>
                <Description><BasketItemDescriptionPlaceholder /></Description>
                <DescriptionChildren><CustomerOptions optionGroups={[]} parentIsLoading /></DescriptionChildren>
                <DeliveryContent><BasketItemDeliveryEstimatePlaceholder /></DeliveryContent>
              </DescriptionContent>
              <TotalContent>
                <Total><BasketItemTotalEstimatePlaceholder /></Total>
                <PriceContent>
                  <PriceInfo><BasketItemPricePlaceholder /></PriceInfo>
                </PriceContent>
                <IssuePricing><BasketItemIssuePricingPlaceholder /></IssuePricing>
              </TotalContent>
            </SubscriptionContent>
            <ModifyContent>
              <RemoveItem>
                <NumberPicker parentIsLoading value={0} onChange={() => {}} hideControls={false} />
              </RemoveItem>
              <LinksWrappers>
                <BasketItemRemovePlaceholder />
              </LinksWrappers>
            </ModifyContent>
            <FreeGift parentIsLoading />
          </ContentWrapper>
        </BasketItemWrapper>
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper>
      <BasketItemWrapper>
        <ImageWrapper>
          <ProductImage
            image={image}
            title={`${title} Cover`}
            imgWidth={75}
            variants={[
              {
                imgWidth: 80,
                mediaMinWidth: '1024px'
              },
              {
                imgWidth: 90,
                mediaMinWidth: '768px'
              },
              {
                imgWidth: 120,
                mediaMinWidth: '414px'
              }
            ]}
          />
        </ImageWrapper>
        <ContentWrapper>
          <SubscriptionContent>
            <DescriptionContent>
              <SubscriptionInfo>
                <Title text={title} tag='h5'/>
                <SubscriptionType>{subscriptionTitle}</SubscriptionType>
              </SubscriptionInfo>
              <Description>{description} </Description>
              <DescriptionChildren>{children}</DescriptionChildren>
              {estimatedDeliveryDate && <DeliveryContent>{`Delivery est. ${timestampToDate(estimatedDeliveryDate)}`}</DeliveryContent>}
            </DescriptionContent>
            <TotalContent>
              <Total>Total</Total>
              <PriceContent>
                {(!hasFollowOnPricing || (originalPrice && originalPrice <= price)) && <OriginalPrice>{originalPrice}</OriginalPrice>}
                <PriceInfo>{price}</PriceInfo>
              </PriceContent>
              <IssuePricing>{pricePerIssue}</IssuePricing>
            </TotalContent>
          </SubscriptionContent>
          <ModifyContent>
            <RemoveItem>
              <NumberPicker value={qty} onChange={i => onClick(i)} hideControls={hideQty}/>
            </RemoveItem>
            <LinksWrappers>
              {remove && <a href='www.https://www.magazinesdirect.com/' role='button' rel='noreferrer' target='_blank' data-testid={`${testid}-remove`} onClick={handleRemove}><Icon width="14" glyph="trash_can" title="Trash Can"/>Remove</a>}
            </LinksWrappers>
          </ModifyContent>
          {promotion && <FreeGift testid={`${testid}-freegift`} icon={promotion.freeGiftIcon} description={promotion.freeGiftDescription} />}
        </ContentWrapper>
      </BasketItemWrapper>
    </ItemWrapper>
  );
};
