import { styled, css } from 'styled-components';

export const ContentWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.black};

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const ContentItem = styled.li`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.black};

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 30px;
    width: 33.333%;
    border-right: 1px solid ${props => props.theme.colors.black};
    border-bottom: 0;
  }

  &:last-child {
    border-right: 0;
    border-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
`;

export const RichTextWrapper = styled.div`
  padding-top: 10px;
  overflow: hidden;
`;

export const ContentTitle = styled('h3').withConfig({
  shouldForwardProp: props => !['hasPlaceholder'].includes(props)
})<{ hasPlaceholder?: boolean }>`
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  text-transform: ${props => props.theme.fontDecor.fd4};
  background-color: ${props => props.theme.colors.white};
  padding: 15px 25px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: ${props => props.theme.fontSizes.fs4};
    line-height: ${props => props.theme.lineHeights.lh2};
  }

  ${props => props.hasPlaceholder && css`
    svg {
      height: ${props.theme.lineHeights.lh1};
      width: auto;
    }

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      svg {
        height: ${props => props.theme.lineHeights.lh2};
      }
    }
  `}
`;

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  padding: 1.5px 0;
  border: 1px solid ${props => props.theme.colors.black};
  border-top: 0;
  background: repeating-linear-gradient(to bottom, ${props => props.theme.colors.white} 0 7px, ${props => props.theme.layout.primary} 7px 8px);
  background-size: 100% 100%;

  span {
    font-size: ${props => props.theme.fontSizes.fs2};
    color: ${props => props.theme.layout.primary};
    margin-right: 10px;
  }
`;
