import React from 'react';

import { Icon } from '../../atoms/Icon';

import type { MainNavigation as MainNavigationProps } from './mainNavigation.types';

import {
  Navigation,
  NavigationItem,
  NavItemLink,
  SubMenuWrapper,
  SubMenu,
  SubMenuItem
} from './mainNavigation.styles';

/**
 *
 * @param {string} current Current selected item.
 * @param {import('./mainNavigation.types').NavLink} items Navigation items.
 * @param {(e:string) => void} onClick Callback on item click.
 *
 */

export const MainNavigation = ({ current, chnl, items, onClick, id, testid = 'navigation' }:MainNavigationProps):React.ReactElement => {

  const handleClick = (e:React.MouseEvent, url?:string, target?:string | null) => {
    e.preventDefault();
    if( url) onClick(url, target);
  };

  return (
    <Navigation {...(id ? { id } : {})} data-testid={testid}>
      <nav role="navigation" aria-label="Main Navigation">
        <ul>
          {items.map(item=> {
            const hasSubMenu = item.items && item.items?.length > 0;
            const url = item.channelAware ? `/${chnl}${item.href}` : item.href;
            const selected = url === current;
            return (
              <NavigationItem key={item.code} data-testid={`${testid}-item`} >
                <NavItemLink href={url} onClick={(event:React.MouseEvent<HTMLAnchorElement>) => handleClick(event, url, item.target)} selected={selected}>
                  {selected && <span className="visually-hidden">Current Page: </span>}
                  <span>{item.name}</span>
                  {hasSubMenu && <Icon width="23" glyph="arrow_down" title="arrow down fill" />}
                </NavItemLink>
                {hasSubMenu && (
                  <SubMenuWrapper>
                    <SubMenu>
                      {item.href && (<SubMenuItem data-testid={`${testid}-subitem`} initial={true}><a href={url} onClick={event => handleClick(event, url, item.target)}>{`View all ${item.name}`}</a></SubMenuItem>)}
                      {item.items?.map(subItem => {
                        const subUrl = subItem.channelAware ? `/${chnl}${subItem.href}` : subItem.href;
                        return (
                          <SubMenuItem data-testid={`${testid}-subitem`} key={subItem.code}><a href={subUrl} onClick={event => handleClick(event, subUrl, subItem.target)}>{subItem.name}</a></SubMenuItem>
                        );
                      })}
                    </SubMenu>
                  </SubMenuWrapper>
                )}
              </NavigationItem>
            );
          })}
        </ul>
      </nav>
    </Navigation>
  );
};
