export type SiteUrl = {
  [key:string]: {
    baseUrl: string
    stagingUrl?: string
    pageUrl?: string
  }
}
export const siteUrls:SiteUrl = {
  magazinesdirect: {
    baseUrl: 'https://arcade.magazinesdirect.com.master.magsdirect-prod-eks-euw1.futureplc.engineering',
    stagingUrl: 'https://arcade.magazinesdirect.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk'
  },
  horseandhound: {
    baseUrl: 'https://subscribe.arcade.horseandhound.co.uk',
    stagingUrl: 'https://subscribe.arcade.horseandhound.co.uk.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk'
  },
  discover: {
    baseUrl: 'https://discover.arcade.futureplc.com',
    stagingUrl: 'https://discover.arcade.futureplc.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk'
  },
  guitarworld: {
    baseUrl: 'https://subscribe.arcade.guitarworld.com',
    stagingUrl: 'https://subscribe.arcade.guitarworld.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/guitar-world-unlimited-digital-subscription/dp/06b30009'
  },
  digitalcameraworld: {
    baseUrl: 'https://subscribe.arcade.digitalcameraworld.com',
    stagingUrl: 'https://subscribe.arcade.digitalcameraworld.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/digital-camera-world-unlimited-digital-subscription/dp/db90bcdb'
  },
  creativebloq: {
    baseUrl: 'https://subscribe.arcade.creativebloq.com',
    stagingUrl: 'https://subscribe.arcade.creativebloq.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/creative-bloq-unlimited-digital-subscription/dp/f14390e5'
  },
  cyclingweekly: {
    baseUrl: 'https://subscribe.arcade.cyclingweekly.com',
    stagingUrl: 'https://subscribe.arcade.cyclingweekly.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/cycling-weekly-unlimited-digital-subscription/dp/9f3fa1d1'
  },
  fourfourtwo: {
    baseUrl: 'https://subscribe.arcade.fourfourtwo.com',
    stagingUrl: 'https://subscribe.arcade.fourfourtwo.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/fourfourtwo-subscription/dp/8ab7216f'
  },
  cyclingnews: {
    baseUrl: 'https://subscribe.arcade.cyclingnews.com',
    stagingUrl: 'https://subscribe.arcade.cyclingnews.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/cycling-news-digital-subscription/dp/a82ef852'
  },
  theweek: {
    baseUrl: 'https://subscribe.arcade.theweek.com',
    stagingUrl: 'https://subscribe.arcade.theweek.com.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl: '/uk/the-week-subscription/dp/230bb860'
  },
  countrylife: {
    baseUrl: 'https://subscribe.arcade.countrylife.co.uk',
    stagingUrl: 'https://subscribe.arcade.countrylife.co.uk.master.magsdirect-staging-eks-euw1.futureplc.engineering',
    pageUrl:'/uk/country-life-subscription/dp/c6f6b728'
  }
};
