import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Icon } from '@arcade/component-library';

import Logo from '../Logo/logo';

import {
  SideMenuWrapper,
  MenuWrapper,
  MenuTitle,
  MenuList,
  MenuLink
} from './sideMenu.styles';
import useUserRole from '../../hooks/useUserRole';

const SideMenu = ():React.ReactElement => {
  const { logout } = useAuth0();
  const { isDeveloper } = useUserRole();

  const handleLogout = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  return (
    <SideMenuWrapper>
      <div>
        <Logo />
        <MenuWrapper>
          <MenuTitle>MENU</MenuTitle>
          <MenuList>
            <MenuLink>
              <NavLink to='/'>
                <Icon glyph='book_open'/> <span>Dashboard</span>
              </NavLink>
            </MenuLink>
            <MenuLink>
              <NavLink to='campaign/new'>
                <Icon glyph='add'/> <span>New Campaign</span>
              </NavLink>
            </MenuLink>
            <MenuLink>
              <NavLink to='campaign/user/1234'>
                <Icon glyph='star'/> <span>My Campaigns</span>
              </NavLink>
            </MenuLink>
          </MenuList>
        </MenuWrapper>
        <MenuWrapper>
          <MenuTitle>Other</MenuTitle>
          <MenuList>
            <MenuLink>
              <NavLink to='profile'>
                <Icon glyph='account_solid'/> <span>Profile</span>
              </NavLink>
            </MenuLink>
            {
              isDeveloper &&
              <MenuLink>
                <NavLink to='developers'>
                  <Icon glyph='filters'/> <span>Developers</span>
                </NavLink>
              </MenuLink>
            }
          </MenuList>
        </MenuWrapper>
      </div>
      <a href='/' role='button' onClick={e => handleLogout(e)}>
        <Icon glyph='arrow_right_line'/> <span>Logout</span>
      </a>
    </SideMenuWrapper>
  );
};

export default SideMenu;
