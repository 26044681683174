import { styled, css } from 'styled-components';

export const FormFieldWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['half'].includes(props)
})<{ half: boolean }>`
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    width: ${props => props.half ? 'calc(50% - 10px)' : '100%'};
  }
`;

export const FieldLabel = styled.label<{ disabled: boolean}>`
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${({ theme }) => theme.fontSizes.fs0};
  line-height: ${({ theme }) => theme.lineHeights.lh0};
  color: ${props => props.theme.colors.black};
  margin-bottom: 2px;
  ${props => props.disabled && css`
    color: ${props => props.theme.colors.darkGrey};
  `}

  span {
    font-weight: ${props => props.theme.fontWeights.fw1};
    color: ${props => props.theme.colors.darkGrey};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletS}) {
    font-size: ${({ theme }) => theme.fontSizes.fs1};
    line-height: ${({ theme }) => theme.lineHeights.lh1};
  }
`;

export const ErrorLabel = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  color: ${props => props.theme.colors.error};
  font-weight: ${props => props.theme.fontWeights.fw2};
`;

export const ExtraInfo = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  color: ${props => props.theme.colors.darkGrey};
  line-height: ${props => props.theme.lineHeights.lh0};
`;

export const InlineFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    flex-direction: row;
  }
`;
