import { styled, css } from 'styled-components';

export const ProductOptionListItemTitlePlaceholderWrapper = styled.div`
  svg {
    height: clamp(${props => props.theme.lineHeights.lh3}, 3vw, ${props => props.theme.lineHeights.lh4});
    width: auto;
  }
`;

export const ProductOptionListItemPricingPlaceholderWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['isMobile'].includes(props)
})<{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile && css`
    margin-top: 15px;

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      display: none;
    }
  `}
  ${({ isMobile }) => !isMobile && css`
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      display: block;
    }
`}
`;

export const ProductOptionListItemSavingPlaceholderWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
    align-self: flex-end;
  }
`;
