import { styled, css } from 'styled-components';

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: row;

    button {
      box-sizing: border-box;
      height: 100%;
      align-self: flex-start;
    }
  }
`;

export const ProductWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['hasSignpost'].includes(props)
})<{ hasSignpost: boolean }>`
  flex-grow: 1;
  width: 100%;

  &:last-child {
    padding-right: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    ${props => props.hasSignpost
    ? css`
      padding-top: 0;
      padding-right: 5px;`
    : css`
      padding-top: 24px;
      padding-right: 5px;`
}
  }
`;
