import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductSubtitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    style={{ marginBottom: '30px' }}
    width={300}
    height={18}
    {...props}
  >
    <rect x="0" y="3" rx="0" ry="0" width="300" height="12" />
  </LoadingPlaceholder>
);

export default ProductSubtitlePlaceholder;
