import React from 'react';

import { DropDownMenu } from '../../atoms/DropDownMenu';
import { RadioGroup } from '../../atoms/RadioGroup';

import type { SortingGroup as SortingGroupProps } from './sortingGroup.types';

/**
 *
 * @param {import('./sortingGroup.types').Item[]} items Option to be shown on the panel.
 * @param {string} selected Selected sorting item id.
 * @param {(_id:string) => void} onChange Callback on option selection.
 *
 */

export const SortingGroup = ({ items, selected, onChange, testid = 'sorting', id }:SortingGroupProps):React.ReactElement => {

  const handleSelection = (event:React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };
  const [selectedItem] = items.filter(item => item.id === selected);
  return (
    <DropDownMenu title={`Sort: ${selectedItem.label}`} direction='right' testid={testid} {...(id ? { id } : {})}>
      <RadioGroup
        name='sorting'
        items={items.map(({ id, label }) => ({ id, label, value: id }))}
        selected={selected}
        onChange={handleSelection}
        testid={`${testid}-radio`}
      />
    </DropDownMenu>
  );
};
