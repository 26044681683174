import React, { forwardRef, ForwardedRef, useState, useEffect } from 'react';

import { Icon } from '../Icon';

import type { Checkbox as CheckboxProps } from './checkbox.types';

import {
  CheckboxContainer,
  CheckboxWrapper,
  CheckboxHidden,
  IconWrapper,
  LabelText,
  LabelWrapper,
  Required
} from './checkbox.styles';

/**
 * Checkbox
 *
 * @param {string} [id] Checkbox id.
 * @param {boolean} [disabled=false] Disable the checkbox.
 * @param {boolean} [checked=false] if checkbox is checked
 * @param {string} [label] Label for the checkbox.
 * @param {(event:boolean) => void} [onChange] Checkbox change event.
 * @param {boolean} [required] If the checkbox is required.
 */
export const Checkbox = forwardRef((props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { id, label, testid = 'checkbox', required, error = false, className } = props;

  const [checked, setChecked] = useState<boolean>(props.checked || false);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if(props.checked !== undefined) setChecked(props.checked);
  }, [props.checked]);

  const disabled = props.disabled || false;

  const onChange = ():void => {
    const { onChange } = props;

    if (onChange) onChange(!checked);

    setChecked(!checked);
  };

  const onFocus = (): void => {
    setFocused(true);
  };

  const onBlur = (): void => {
    setFocused(false);
  };

  return (
    <CheckboxContainer className={className}>
      <CheckboxHidden
        {...(id ? { id } : {})}
        checked={checked}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        ref={ref}
        data-testid={testid}
      />

      <CheckboxWrapper disabled={disabled} focused={focused} checked={checked} error={error} role='checkbox' aria-checked={checked} { ...(id ? { 'aria-labelledby': `${id}-label` } : {}) }>
        <IconWrapper checked={checked}>
          <Icon glyph='check_line' width='16' height='16' />
        </IconWrapper>
      </CheckboxWrapper>

      <LabelWrapper { ...(id ? { id: `${id}-label` } : {}) } { ...( className ? { className: `${className}-label` } : {}) }>
        {required && <Required>*</Required>}
        {label ? <LabelText disabled={disabled} dangerouslySetInnerHTML={{ __html: label }}/> : null}
      </LabelWrapper>
    </CheckboxContainer>
  );
});
