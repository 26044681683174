import React, { useRef, useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import type { ForwardedRef } from 'react';
import { throttle } from 'lodash';

import type { Result, SuggestField as SuggestFieldProps } from './suggestField.types';

import { TextBox } from '../../atoms/TextBox';
import { Tag } from '../../atoms/Tag';

import { executeOnKeyDown } from '../../../utils';

import {
  FieldWrapper,
  ResultsWrapper,
  ResultsList,
  ResultButton,
  TagsWrapper,
  TagWrapper,
  SuggestionsHeading,
} from './suggestField.styles';

/**
 *
 * @param {string} placeholder Placeholdre to display in the input.
 * @param {import('./suggestField.types').Result[]} [results=[]] List of result to be display.
 * @param {(e:string) => void} onChange Callback on input value changed.
 * @param {(id:string, value:string) => void} handleResultClick Callback on result ite click.
 * @param {import('./suggestField.types').Result[]} [selected=[]] Selected values on multipleSelection
 * @param {import('../../atoms/TextBox/textBox.types.ts').TextBoxState} [state=normal] The state of the input (normal, error, verified).
 *
 */

export const SuggestField = forwardRef(({ id, testid = 'suggest-field', placeholder, results = [], onChange, handleResultClick, selected = [], onRemoveSelected, state = 'normal' }:SuggestFieldProps, ref: ForwardedRef<HTMLInputElement>):React.ReactElement => {
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  // Give parent the input ref as the ref
  useImperativeHandle(ref, () => {
    return input.current as HTMLInputElement;
  }, [input.current]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  const changeHandler = async (event: React.FormEvent<HTMLInputElement>):Promise<void> => {
    const inputValue = (event.target as HTMLTextAreaElement).value;
    const trimmedvalue = inputValue.trim();
    onChange(trimmedvalue);
  };

  const debouncedChangeHandler = useMemo(
    () => throttle(changeHandler, 1000)
    , []
  );

  const getHighlightedPart = (text:string, highlight:string | undefined):string => {
    if (highlight === undefined) return text;
    let finalText = '';
    const digits:number[] = [0];
    const subStrings:string[] = [];

    const highlightParts = highlight.split(',');
    highlightParts.forEach(part => {
      const [start, end] = part.split('-');
      digits.push(Number(start), Number(end));
    });
    digits.push(text.length);

    digits.forEach((digit, i) => digits[i+1] !== undefined && subStrings.push(text.substring(digit, digits[i+1])));
    subStrings.forEach((str, i) => {
      if (i % 2 === 0) {
        finalText = `${finalText}${str}`;
      } else {
        finalText = `${finalText}<b>${str}</b>`;
      }
    });
    return finalText;
  };

  const getResultText = (result?:Result ): string => {
    if(result) {
      const { text, description, highlight } = result;
      if(highlight && highlight !== '') {
        const [textHighlight, descHighlight] = highlight.split(';');
        const highligthedDesc = description && description !== '' ? `<span>${getHighlightedPart(description, descHighlight)}</span>` : '';
        const highligthedText = text && text !== '' ? getHighlightedPart(text, textHighlight) : '';
        return `${highligthedText}${highligthedDesc}`;
      } else {
        return `${text || ''}${description ? `<span>${description}</span>` : ''}`;
      }
    }
    return '';
  };

  const handleTagRemove = (id:string) => {
    if(onRemoveSelected) onRemoveSelected(id);
  };

  const onResultClick = (id:string):void => {
    const inputValue = input.current?.value || '';
    handleResultClick(id, inputValue);
  };

  useEffect(() => {
    setSuggestionsOpen(!!results.length);
    if (parentRef.current) {
      const focusOutEvent = (e: FocusEvent) => {
        if (!parentRef?.current?.contains((e.relatedTarget ?? null) as Node | null)) {
          // No focus inside component so close menu
          setSuggestionsOpen(false);
        }
      };
      const focusInEvent = (e: FocusEvent) => {
        if (input.current === e.target) {
          setSuggestionsOpen(!!results.length);
        }
      };
      parentRef.current.addEventListener('focusout', focusOutEvent);
      parentRef.current.addEventListener('focusin', focusInEvent);
      return () => {
        parentRef.current?.removeEventListener('focusout', focusOutEvent);
        parentRef.current?.removeEventListener('focusin', focusInEvent);
      };
    }
    return () => {};
  }, [parentRef, results]);

  return (
    <FieldWrapper hasSelected={selected.length > 0} ref={parentRef}>
      <TextBox
        placeholder={placeholder}
        id={id}
        onChange={debouncedChangeHandler}
        ref={input}
        aria-controls={`${id}_results`}
        testid={testid}
        type='search'
        state={state}
      />
      {selected?.length > 0 && <TagsWrapper>
        {selected.map(sel => <TagWrapper key={sel.id}><Tag id={sel.id} text={sel.text || ''} onRemove={handleTagRemove} /></TagWrapper>)}
      </TagsWrapper>}
      <ResultsWrapper visible={suggestionsOpen} aria-live='polite' id={`${id}_results`} role="region" hasSelected={selected.length > 0}>
        <SuggestionsHeading>SUGGESTIONS</SuggestionsHeading>
        <ResultsList data-testid={`${testid}-results`}>
          {results.length > 0 && results.map(result => {
            if(result.error) return (
              <li key={result.error}>
                <p>No results</p>
              </li>
            );
            return (
              <li key={result.id} data-testid={`${testid}-result`}>
                <ResultButton
                  data-testid={`${testid}-result-btn`}
                  role='button'
                  tabIndex={0}
                  onClick={() => onResultClick(result.id)}
                  onKeyDown={(e:React.KeyboardEvent<HTMLAnchorElement>) => executeOnKeyDown(e) && onResultClick(result.id)}
                  dangerouslySetInnerHTML={{ __html: getResultText(result) }}
                />
              </li>
            );
          })}
        </ResultsList>
      </ResultsWrapper>
    </FieldWrapper>
  );
});
