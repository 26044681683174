import { styled, css } from 'styled-components';

export const TitlePlaceholderWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['titleTag'].includes(props)
})<{ titleTag?: string }>`
  svg {
    ${props => props.titleTag && css`
      width: auto;
    `}
    ${props => props.titleTag === 'h1' && css`
      height: clamp(${props.theme.lineHeights.lh5}, 3vw, ${props.theme.lineHeights.lh6});
    `}
    ${props => props.titleTag === 'h2' && css`
      height: clamp(${props.theme.lineHeights.lh4}, 3vw, ${props.theme.lineHeights.lh5});
    `}
    ${props => props.titleTag === 'h3' && css`
      height: clamp(${props.theme.lineHeights.lh3}, 3vw, ${props.theme.lineHeights.lh4});
    `}
    ${props => props.titleTag === 'h4' && css`
      height: clamp(${props.theme.lineHeights.lh2}, 3vw, ${props.theme.lineHeights.lh3});
    `}
    ${props => props.titleTag === 'h5' && css`
      height: clamp(${props.theme.lineHeights.lh3}, 3vw, ${props.theme.lineHeights.lh4});
    `}
    ${props => props.titleTag === 'h6' && css`
      height: clamp(${props.theme.lineHeights.lh0}, 3vw, ${props.theme.lineHeights.lh1});
    `}
  }
`;
