import React from 'react';
import type { ProductOptionTab as ProductOptionTabProps } from './productOptionTab.types';
import ProductOptionTabPlaceholder from './placeholders/productOptionTabPlaceholder';

import {
  ProductOptionTabWrapper,
  ProductContentWrapper,
  ProductOptionTabTitle,
  Description,
  Pricing,
  Signpost,
  TabRadio,
  RadioButton
} from './productOptionTab.styles';
/**
 *
 * @param {string} title Option's title.
 * @param {string} price Option's price.
 * @param {string} [priceUnit] Option's price label.
 * @param {string} description Option's description.
 * @param {string} [signpost] Additional incentive.
 * @param {boolean} [selected=false] If the tab is selected.
 * @param {() => void} onClick Tab click callback.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading.
 * @param {boolean} [inStock = true] If item is in stock.
 *
 */

export const ProductOptionTab = ({ title, price, priceUnit, description, signpost, selected=false, onClick, text, parentIsLoading = false, inStock = true }: ProductOptionTabProps): React.ReactElement => {
  return (
    <ProductOptionTabWrapper type='button' onClick={onClick} data-testid='option' hasSignpost={!!signpost} inStock={inStock}>
      {signpost && inStock && <Signpost selected={selected} data-testid='sign-post'> {signpost} </Signpost>}
      <ProductContentWrapper selected={selected} hasSignpost={!!signpost} data-testid='select-option' inStock={inStock}>
        {parentIsLoading ? (
          <ProductOptionTabPlaceholder />
        ):(
          <>
            <TabRadio>
              <RadioButton checked={selected} disabled={!inStock} />
            </TabRadio>
            <span>
              {title && <ProductOptionTabTitle inStock={inStock}> {title}  </ProductOptionTabTitle>}
              {price && text && priceUnit && <Pricing>{ `${text} ${price} / ${priceUnit}`}</Pricing>}
              <Description selected={selected} inStock={inStock}>{!inStock ? 'Out of Stock' : description}</Description>
            </span>
          </>
        )}
      </ProductContentWrapper>
    </ProductOptionTabWrapper>
  );
};
