import { styled } from 'styled-components';

export const CartWrapper = styled.div`
  display: inline-block;

  &:focus {
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.colors.focus};
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.black : theme.colors.white};
    text-decoration: none;
    padding: 4px 10px;
    line-height: ${props => props.theme.lineHeights.lh0};
    font-size: ${props => props.theme.fontSizes.fs0};
    cursor: pointer;
    background: none;
    border: 0 none;

    svg {
      padding: 0;
      line-height: ${props => props.theme.lineHeights.lh0};
      font-size: ${props => props.theme.fontSizes.fs1};
    }

    @media (min-width: ${props => props.theme.breakpoints.laptop}) {
      flex-direction: row;
      padding: 10px;
      line-height: ${props => props.theme.lineHeights.lh2};
      font-size: ${props => props.theme.fontSizes.fs1};

      svg {
        padding-right: 5px;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.layout.primary : theme.colors.white};
      text-decoration: ${props => props.theme.fontDecor.fd6};

      svg {
        color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.black : theme.colors.white};
      }
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
`;

export const ItemNotification = styled.div`
  position: absolute;
  top: -2px;
  right: -5px;
  color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.layout.primary : theme.colors.white};
  font-size: 10px;
  line-height: 10px;
  font-weight: 800;

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    right: 2px;
  }
`;
