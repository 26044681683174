import React, { useState, useContext, useRef, useCallback } from 'react';
import { apiClient } from 'src/lib/apiConfig';
import { userContext } from 'src/context/userContext';
import { components } from 'src/lib/api/openapi';

import { SuggestField } from '@arcade/component-library';
import type { SuggestFieldType, TextBoxState } from '@arcade/component-library';

type PromoFieldType = {
  handleSelection: (_val:string) => void
  handleRemove: (_val: string) => void
  selectedPromos?: SuggestFieldType['results']
  state: TextBoxState
}

export type SuggestFieldPromoResultType = {
  id: string,
  text: string
};

type PromotionResults = components['schemas']['BluebirdPromotion-search.read'][];

export const getPromoStringArrayFromObjects = (objArr: SuggestFieldType['results']): string[] => objArr?.map(obj => obj.id) ?? [];

export const getPromoObjArrayFromStrings = (stringArr: string[]): SuggestFieldType['results'] => stringArr.map(str => ({ id: str, text: str }));

const convertIntoSuggestFieldOptions = (promotionResults: PromotionResults): SuggestFieldPromoResultType[] => {
  return promotionResults.map(result => {
    if (result.code) {
      return {
        id: result.code,
        text: result.name ?? result.code
      };
    }
  }) as SuggestFieldPromoResultType[];
};

const PromoField = ({ handleSelection, handleRemove, selectedPromos = [], state }:PromoFieldType):React.ReactElement => {
  const [suggestFieldResults, setSuggestFieldResults] = useState<SuggestFieldPromoResultType[]>([]);
  const abrtController = useRef(new AbortController());
  const textInputRef = useRef<HTMLInputElement>(null);

  const getPromotions = useCallback(async (token: string, searchQuery: string) =>
    await apiClient.promotionApi.getPromotions({ token, promoCodeQuery: searchQuery, abortSignal: abrtController.current.signal }),
  [abrtController.current]);

  const userDetails = useContext(userContext);

  const handleOnSearchChange = async (value: string) => {
    abrtController.current.abort('Stop last call as new input value');
    abrtController.current = new AbortController();
    if (value === '') {
      setSuggestFieldResults([]);
    } else {
      const { data: promotionData, error } = await getPromotions(userDetails.accessToken ?? '', value);
      if (error) { console.error(error.detail); return; }
      setSuggestFieldResults(convertIntoSuggestFieldOptions((promotionData?.results ?? []) as PromotionResults));
    }
  };

  const onSelection = (val: string) => {
    // Only select if we haven't already selected it
    if (!getPromoStringArrayFromObjects(selectedPromos).some(id => id === val)) {
      abrtController.current.abort('Stop last call as option selected');
      abrtController.current = new AbortController();
      setSuggestFieldResults([]);
      if (textInputRef.current) {
        textInputRef.current.value = '';
      }
      handleSelection(val);
    }
  };

  return (
    <SuggestField
      placeholder='Start typing'
      results={suggestFieldResults}
      onChange={handleOnSearchChange}
      handleResultClick={onSelection}
      onRemoveSelected={handleRemove}
      selected={selectedPromos}
      ref={textInputRef}
      state={state}
    />
  );
};

export default PromoField;
