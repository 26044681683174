import React from 'react';

import { Button } from '../Button';

import type { Form as FormProps } from './form.types';

import {
  FormWrapper,
  FormFieldsWrapper,
  FormLegend,
  FormActionWrapper,
  FormError
} from './form.styles';

/**
 * Form container
 * @param {string} id Form id.
 * @param {(data:any) => void} onSubmit Callback for form submission
 * @param {React.ReactNode} children Content of the form.
 * @param {boolean} [isLoading=false] Form loading.
 * @param {boolean} [isError=false] Form submission error.
 * @param {string} actionLabel Form submit label.
 */

export const Form = ({ children, onSubmit, isLoading = false, isError = false, actionLabel, id, testid = 'details' }:FormProps):React.ReactElement => (
  <FormWrapper onSubmit={onSubmit} id={id} data-testid={`form-${testid}`}>
    <fieldset>
      <FormLegend>* Indicates required information</FormLegend>
      <FormFieldsWrapper>
        {children}
      </FormFieldsWrapper>
      {isError && <FormError>There was an error submitting the form.</FormError>}
      <FormActionWrapper>
        <Button id={`${id}_cta`} btnType='filled' testid={testid} size='large' responsive type='submit' disabled={isLoading}>{actionLabel}</Button>
      </FormActionWrapper>
    </fieldset>
  </FormWrapper>
);
