import { styled, css } from 'styled-components';

export const PlaceHolder = styled('div').withConfig({
  shouldForwardProp: props => !['imageWidth', 'imageHeight'].includes(props)
})<{ imageWidth: number, imageHeight: number }>`
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: ${({ imageWidth, imageHeight }) => imageHeight / imageWidth * 100 + '%'};

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const PictureWrapper = styled('picture').withConfig({
  shouldForwardProp: props =>
    !['loaded'].includes(props)
})<{loaded: boolean}>`
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 400ms ease 0ms;
  ${props => props.loaded === true && css`
    opacity: 1;
  `}
`;

export const MainImage = styled('img').withConfig({
  shouldForwardProp: props =>
    !['objectFit', 'positionX', 'positionY'].includes(props)
})<{ objectFit: string, positionX: string, positionY: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  display: block;
  object-fit: ${props => props.objectFit};
  object-position: ${props => props.positionX} ${props => props.positionY};
`;
