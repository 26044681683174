import React, { useEffect, useRef } from 'react';

import { BasketItem, ProductTile } from '../../molecules';
import { Button, Icon, Title } from '../../atoms';
import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import type { Basket as BasketProps } from './basket.types';

import {
  BasketPanel,
  BasketTitle,
  BasketClose,
  BasketNotification,
  ScrollableBasket,
  BasketItems,
  BasketItemsOverlay,
  NoItems,
  BasketTotal,
  BasketCta,
  BasketMore
} from './basket.styles';

/**
 *
 * @param {import('../../molecules/BasketItem/basketItem.types')[]} [items=[]] Items in teh basket.
 * @param {import('../../molecules/ProductTile/productTile.types')[]} [related=[]] Reletade items.
 * @param {(id:string, qty:number) => void} onQtyChange Callback whne modifying item quantity.
 * @param {boolean} [processing=false] If the basket is processing.
 * @param {string} [total='0'] Basket total.
 * @param {() => void} onViewBasket Callback for "View Basket".
 * @param {boolean} [isError=false] If an error accour when adding or modifying item qty.
 * @param {boolean} open If the basket is showing.
 * @param {string | null} notification Notification text to show in the basket.
 * @param {(open:boolean) => void} setOpen Open the basket.
 * @param {(notification: string) => void} setNotification Set the notification type.
 * @returns
 */

export const Basket = ({ open, notification, setOpen, setNotification, items = [], related = [], onQtyChange, processing = false, total = '0', onViewBasket, isError = false, testid = 'basket' }:BasketProps):React.ReactElement => {

  const totalItems = ():number => {
    let total = 0;
    items && items.forEach(item => total += item.qty);
    return total;
  };

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification(null), 4000);
    }
  }, [notification]);

  const basketRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e:React.MouseEvent | MouseEvent) => {
      if(open && basketRef.current && !basketRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.body.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.addEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  return (
    <BasketPanel ref={basketRef} open={open} data-testid={testid} role="dialog" aria-labelledby="basket-title" aria-modal="true">
      <BasketTitle>
        <Title text="Your basket" tag='h4' id="basket-title" />
        <BasketClose data-testid={`${testid}_close`} role="button" aria-label="Close basket" tabIndex={0} onClick={() => setOpen(false)} onKeyDown={(e:React.KeyboardEvent<HTMLAnchorElement>) => executeOnKeyDown(e) && setOpen(false)}/>
      </BasketTitle>
      <ScrollableBasket>
        <BasketNotification className={!!notification ? 'fade-in' : ''} error={isError} data-testid={`${testid}_notification`}>
          {isError ? <Icon glyph='error_warning' title="Error" /> : <Icon glyph='check_circle' title="Checkbox" />}
          <p>{notification}</p>
        </BasketNotification>
        <BasketItems>
          <BasketItemsOverlay show={processing} />
          {items.length > 0 ? items.map(item => (
            <BasketItem
              key={item.id}
              {...item}
              onClick={qty => onQtyChange(item?.id as string, qty)}
            />
          ))
            : <NoItems>No items</NoItems>
          }
        </BasketItems>
        <BasketTotal>
          <p>{`${totalItems()} item${totalItems() > 1 ? 's' : ''} in your basket`}</p>
          <p>{`Total: ${total}`}</p>
        </BasketTotal>
        <BasketCta>
          <Button testid={testid} btnType='filled' size='large' onClick={onViewBasket} responsive >View basket and checkout</Button>
        </BasketCta>
        {related.length > 0 && <BasketMore>
          <p>People also like these</p>
          <ul>
            {related.map(product => (
              <li key={product.id} data-testid="basket_more">
                <ProductTile
                  {...product}
                />
              </li>
            ))}
          </ul>
        </BasketMore>}
      </ScrollableBasket>
    </BasketPanel>
  );
};
