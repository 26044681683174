import { css, styled } from 'styled-components';

export const FieldWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['hasSelected'].includes(props)
})<{hasSelected: boolean}>`
  position: relative;

  ${props => props.hasSelected && css`
    input {
      padding-top: 30px;
      height: 64px;
    }
  `}
`;

export const TagsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 10;
  width: 100%;
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
`;

export const TagWrapper = styled.div`
  margin-right: 10px;
`;

export const ResultsWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['visible', 'hasSelected'].includes(props)
})<{visible: boolean, hasSelected: boolean}>`
  width: calc(100% - 40px);
  position: absolute;
  left: 0;
  top: ${props => props.hasSelected ? '62px' : '42px'};
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  visibility: ${props => props.visible ? 'visisble' : 'hidden'};
  padding: 5px 19px;
  border-radius: 6px;
  font-size: ${props => props.theme.fontSizes.fs1};
  color: ${props => props.theme.colors.darkGrey};
  max-height: 240px;
  overflow-y: scroll;
  z-index: ${props => props.theme.zIndexes.zi1};

  ul {
    li {
      padding: 3px 0;
    }
  }
`;

export const ResultButton = styled.a`
  background: none;
  border: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  line-height: ${props => props.theme.lineHeights.lh0};

  &:hover {
    color: ${props => props.theme.colors.black};
  }

  span {
    color: ${props => props.theme.colors.gray};
    font-size: 12px;
    display: block;
  }

  b {
    color: ${props => props.theme.colors.black};
  }
`;
