import { css, styled } from 'styled-components';

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 0;

  > * {
    flex-shrink: 0;
  }
`;

export const TableWrapper = styled.table`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  thead {
    background-color: ${props => props.theme.colors.lightGrey};
    text-align: left;
    text-transform: capitalize;
  }

  th,
  td {
    padding: 12px 15px;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 100%;
    background-color: transparent;
    box-shadow: none;

    thead {
      display: none;
    }
  
    tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      padding: 10px;
      border-radius: 4px;
      background-color: ${props => props.theme.colors.white};
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    td {
      padding: 0.25rem;
    }
  }  
`;

export const TableRow = styled('tr').withConfig({
  shouldForwardProp: prop =>
    !['status'].includes(prop)
})<{ status?: string }>`
  ${props => props.status === 'published' && css`
    background-color: ${props => props.theme.colors.lightBlue};
  `}

  ${props => props.status === 'running' && css`
    background-color: ${props => props.theme.colors.successLight};
  `}
`;

// Helps center pagination
const rowsPerPageWidth = '210px';

export const RowsPerPage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-basis: ${rowsPerPageWidth};

  > * {
    flex-shrink: 0;
  }

  .table-rowspp {
    width: 87px;    
  }
`;

export const PaginationWrapper = styled.div`
  > div {
    width: auto;
    margin: 0;
  }
`;

export const PageCount = styled.div`
  flex-basis: ${rowsPerPageWidth};
  text-align: right;
`;
