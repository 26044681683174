import { createGlobalStyle, withTheme } from 'styled-components';
import { Theme } from './defaultTheme';

type GlobalThemeProps = {
  theme: Theme
}

const globalStyle = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  /* form element don't automatically inherit font-family */
  input,
  label,
  select,
  textarea,
  button,
  fieldset,
  legend,
  datalist,
  output,
  option,
  optgroup {
    font-family: inherit;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    ${props => props.theme.headings.body};
    font-style: normal;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  b,
  strong {
    font-weight: ${props => props.theme.fontWeights.fw3};
  }

  i {
    font-style: ${props => props.theme.fontDecor.fd2};
  }

  .no-scroll {
    height: 100vh;
    overflow: hidden;
    margin: 0;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .page_content_box {
    padding: 20px;
    background-color: ${({ theme }:GlobalThemeProps) => theme.colors.white};
    box-shadow: 0 0 4px 0  ${({ theme }:GlobalThemeProps) => theme.colors.lightGrey};
    border: 1px solid  ${({ theme }:GlobalThemeProps) => theme.colors.lightGrey};
  }

  .image {
    object-fit: contain !important;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`;

export default withTheme(globalStyle);
