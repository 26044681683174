import { styled } from 'styled-components';

export const TitleWrapper = styled.div`
  .h1 {
    ${props => props.theme.headings.xxLarge}
  }

  .h2 {
    ${props => props.theme.headings.xLarge}
  }

  .h3 {
    ${props => props.theme.headings.large}
  }

  .h4 {
    ${props => props.theme.headings.medium}
  }

  .h5 {
    ${props => props.theme.headings.body}
  }

  .h6 {
    ${props => props.theme.headings.small}
  }
`;
