import { styled, css } from 'styled-components';

export const NumberPlaceholderWrapper = styled.div`
  ${({ theme }) => css`
    svg {
      height: clamp(${theme.lineHeights.lh3}, 3vw, ${theme.lineHeights.lh4});
      width: auto;
    }
  `}
`;
