import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductDescriptionPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    width={700}
    height={90}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="700" height="16" />
    <rect x="0" y="24" rx="0" ry="0" width="700" height="16" />
    <rect x="0" y="48" rx="0" ry="0" width="700" height="16" />
    <rect x="0" y="73" rx="0" ry="0" width="170" height="16" />
  </LoadingPlaceholder>
);

export default ProductDescriptionPlaceholder;
