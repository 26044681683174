import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ExtraContentAsideListItemPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    style={{ marginBottom: '5px' }}
    width={135}
    height={26}
    {...props}
  >
    <rect x="0" y="5" rx="0" ry="0" width="135" height="16" />
  </LoadingPlaceholder>
);

export default ExtraContentAsideListItemPlaceholder;
