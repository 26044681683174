import React, { forwardRef } from 'react';

import { Icon } from '../Icon';

import type { TextBox as TextBoxProps } from './textBox.types';

import {
  TextBoxWrapper,
  Input,
  IconWrapper
} from './textBox.styles';

/**
 * Render `<input>` element.
 *
 * @extends {React.ComponentPropsWithRef<'input'>}
 * @param {import('./textBox.types.ts').TextBoxState} [state=normal] The state of the input (normal, error, verified).
 */

export const TextBox = forwardRef<HTMLInputElement, TextBoxProps>((props, ref) => {
  const { testid = 'default', state = 'normal', ...rest } = props;
  const icon = state === 'error' ? 'error_warning' : 'checkbox_circle_fill';
  return (
    <TextBoxWrapper data-testid={`${testid}-textbox`}>
      <Input {...rest} state={state} ref={ref} data-testid={`${testid}-input`}/>
      {state !== 'normal' && <IconWrapper state={state}><Icon glyph={icon} /></IconWrapper>}
    </TextBoxWrapper>
  );
});
