import React, { useRef, useEffect } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import { FormField, Button, DropDown, Title, Icon } from '@arcade/component-library';

import { type CampaignFormType } from '../CampaignForm/campaignForm';

import BannerActionField from './bannerActionField';
import ProductDescriptionActionField from './productDescriptionActionField';
import ProductTitleActionField from './productTitleActionField';
import ProductSubtitleActionField from './productSubtitleActionField';

const availableFields = [
  {
    value: 'banner',
    label: 'Modify Banner',
    component: BannerActionField,
    formValue: 'Banner'
  },
  {
    value: 'productDescription',
    label: 'Modify Product Description',
    component: ProductDescriptionActionField,
    formValue: 'ModifyProductDescription'
  },
  {
    value: 'productTitle',
    label: 'Modify Product Title',
    component: ProductTitleActionField,
    formValue: 'ModifyProductTitle'
  },
  {
    value: 'productSubtitle',
    label: 'Modify Product Subtitle',
    component: ProductSubtitleActionField,
    formValue: 'ModifyProductSubtitle'
  }
];

export type ActionFiledsForm = {
  id?: string
  mode?: string
  targetingString?:string
  type?: string
  position?: string
  content?: string
  description?:string
}[]

import {
  InlineFields,
  FormActionsWrapper
} from './actionFields.styles';

const ActionFields = ({ control, errors, initialData } : {
  control: Control<CampaignFormType>,
  errors: any
  initialData?: ActionFiledsForm
}):React.ReactElement => {
  const addActiondrop = useRef<HTMLSelectElement>(null);

  const { fields, append, remove, replace } = useFieldArray<CampaignFormType>({
    name: 'actions',
    control
  });

  useEffect(() => {
    if(initialData && initialData.length > 0) {
      replace(initialData);
    }
  }, []);

  const handleAddAction = ():void => {
    const dropValue = addActiondrop.current?.value;
    if (dropValue) {
      const formType = availableFields.filter(f => f.value === dropValue)[0].formValue;
      append({
        mode: '',
        targetingString: '',
        type: formType,
        position: ''
      });
    }
  };

  const handleRemove = (index:number):void => {
    remove(index);
  };

  return (
    <FormActionsWrapper>
      <Title tag='h4' text='Actions'/>
      <div>
        {fields.map((field, index) => {
          const Component = availableFields.filter(f => f.formValue === field.type)[0].component;
          return (
            <Component key={field.id} onRemove={handleRemove} {...{ control, index, field, errors }} />
          );
        })}
      </div>
      <hr />
      <InlineFields>
        <FormField half>
          <DropDown
            options={availableFields}
            placeholder='Action'
            id='action_select'
            testid='action_select'
            ref={addActiondrop}
          />
        </FormField>
        <FormField half>
          <Button type="button" onClick={handleAddAction}><Icon glyph='add' />Add</Button>
        </FormField>
      </InlineFields>
    </FormActionsWrapper>
  );
};

export default ActionFields;
