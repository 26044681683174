import { styled, css } from 'styled-components';
import { transparentize } from 'polished';
import { RadioStyles } from '../Radio/radio.styles';

export const ProductContentWrapper = styled('span').withConfig({
  shouldForwardProp: props =>
    !['hasSignpost', 'inStock', 'selected'].includes(props)
})<{ selected: boolean, hasSignpost: boolean, inStock: boolean }>`
  border: 1px solid ${props => props.selected ? props.theme.layout.primary : props.theme.colors.beige};
  box-sizing: border-box;
  transition: ease-in-out;
  padding: 20px;
  margin-bottom: 5px;
  box-shadow: 0 0 2px 0 ${props => props.theme.colors.lightGrey};
  border-radius: 10px;
  text-align: left;
  display: flex;

  ${props => props.selected && css`
    background-color: ${props => transparentize('0.95', props.theme.layout.primary)};
  `}

  ${props => props.hasSignpost && props.inStock && css`
    border-top-left-radius: 0;
    margin-bottom: 28px;

    @media (max-width: ${props.theme.breakpoints.tablet}) {
      margin-bottom: 5px;
    }
  `}

  ${props => props.inStock === false && css`
    border: 1px solid ${props.theme.colors.lightestGrey};
    border-top-left-radius: 10px;
  `}
`;

export const ProductOptionTabTitle = styled('span').withConfig({
  shouldForwardProp: props =>
    !['inStock'].includes(props)
})<{ inStock: boolean }>`
  text-align: left;
  padding-bottom: 3px;
  line-height: ${props => props.theme.lineHeights.lh4};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs4};

  ${props => props.inStock === false && css`
    color: ${props => props.theme.colors.darkGrey};
  `}
`;

export const Pricing = styled.p`
  text-align: left;
  padding-bottom: 15px;
  min-height: 33px;
  font-size: ${props => props.theme.fontSizes.fs0};
  color: ${props => props.theme.colors.darkGrey};
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw1};
`;

export const Description = styled('p').withConfig({
  shouldForwardProp: props =>
    !['inStock', 'selected'].includes(props)
})<{ inStock: boolean, selected: boolean }>`
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  color: ${props => props.theme.colors.darkGrey};
  text-align: left;

  ${props => props.inStock === false && css`
    color: ${props => props.theme.colors.red};
    font-weight: ${props => props.theme.fontWeights.fw2};
  `}

  ${props => props.selected && css`
    color: ${props => props.theme.layout.primary};
  `}
`;

export const Signpost = styled('span')<{ selected: boolean }>`
  display: block;
  width: fit-content;
  text-align: left;
  padding: 3px 20px;
  text-transform: uppercase;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs0};
  ${props => props.selected === true
    ? `
    background-color: ${props.theme.layout.primary};
    color: ${props.theme.colors.white};
    ` : `
    background-color: ${props.theme.colors.beige};
    color: ${props.theme.colors.black};
    `}
`;

export const ProductOptionTabWrapper = styled('button').withConfig({
  shouldForwardProp: props =>
    !['hasSignpost', 'inStock'].includes(props)
})<{ hasSignpost: boolean, inStock: boolean }>`
  cursor: pointer;
  position: relative;
  background: none;
  padding: 0;
  border: 0;
  width: 100%;
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
  text-align: left;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    &:hover ${ProductContentWrapper} {
      border: 1px solid ${props => props.theme.layout.primary};
    }

    &:hover ${Signpost} {
      background-color: ${props => props.theme.layout.primary};
      color: ${props => props.theme.colors.white};
    }
  }

  ${props => props.inStock === false && css`
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

export const TabRadio = styled.span`
  margin-right: 15px;
  display: flex;
`;

export const RadioButton = styled('span').withConfig({
  shouldForwardProp: props =>
    !['focused', 'checked', 'disabled'].includes(props)
})<{ disabled?: boolean, focused?: boolean, checked?: boolean }>`
  ${RadioStyles}

  ${props => props.checked && css`
    border-color: ${props => props.theme.layout.primary};
    background-color: ${props => props.theme.layout.primary};
  `}
`;
