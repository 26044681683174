import { styled } from 'styled-components';

import { fontBasic } from '../../../styles/mixins';

export const RichTextWrapper = styled.div`
  h1 {
    padding: 32px 0;
    text-align: left;
    ${props => props.theme.headings.extraLarge}
  }

  h2 {
    ${props => props.theme.headings.mediumBold}
    margin-bottom: 13px;
  }

  h3 {
    ${props => props.theme.headings.normalBold}
    margin-bottom: 10px;
  }

  p {
    ${props => props.theme.headings.normal}
    margin-bottom: 15px;
  }

  strong {
    font-weight: ${props => props.theme.fontWeights.fw3};
  }

  ul,
  ol {
    margin: 30px 0;
  }

  ul {
    padding-left: 15px;
    margin: 19px 0 23px;

    li {
      list-style: disc;
      list-style-position: outside;
      margin-bottom: 5px;
    }
  }

  ol {
    counter-reset: item;
    padding-left: 15px;

    ol {
      margin: 0;
    }

    li {
      display: block;

      &::before {
        content: counters(item, '.') " ";
        counter-increment: item;
      }
    }
  }

  .alternate tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  .preview {
    text-decoration: none;
    color: ${props => props.theme.colors.black};
    border: 1px solid ${props => props.theme.colors.black};
    padding: 11px 24px;
    display: block;
    text-align: center;

    @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
      padding: 11px 14px;
      display: inline;
    }
  }

  .blockListSubheading {
    margin-bottom: calc(${props => props.theme.spacerSizes.ss5} - 2px);
    ${props => props.theme.headings.xLarge};
    font-weight: ${props => props.theme.fontWeights.fw1};
  }

  .blockListSubheadingLink {
    color: ${props => props.theme.colors.black};
  }

  .blockListDescriptionLink {
    font-weight: ${props => props.theme.fontWeights.fw1};
    color: ${props => props.theme.colors.black};

    ${props => fontBasic(
    props.theme.fontSizes.fs2,
    props.theme.lineHeights.lh2,
  )}
  }

  .blockListDescription {
    color: ${props => props.theme.colors.darkGrey};
  }
`;
