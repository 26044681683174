import React from 'react';

import type { FormField as FormFieldProps } from './formField.types';

import {
  FormFieldWrapper,
  FieldLabel,
  ErrorLabel,
  ExtraInfo
} from './formField.styles';

/**
 * Set layout display and common behaviour of a field inside a form.
 * @param {string} [id] Form field id.
 * @param {string} [label] Form field label.
 * @param {string} [errorLabel=''] Form field error.
 * @param {React.ReactNode} children Actual field.
 * @param {boolean} [required=false] If the field is required.
 * @param {string} [extraInfo] Field additional information.
 * @param {boolean} [half=false] if the field takes half of the width of the form.
 * @param {boolean} [disabled=false] If the field is disabled.
 */
export const FormField = ({ id, label, errorLabel = '', children, required = false, extraInfo, half = false, testid = 'formfield', disabled = false }:FormFieldProps):React.ReactElement => (
  <FormFieldWrapper half={half} data-testid={`field-${testid}`} >
    {label ? <FieldLabel htmlFor={id} disabled={disabled}>{label}{required ? '*' : <span> (optional)</span>}</FieldLabel> : null}
    {children}
    {errorLabel !== '' && <ErrorLabel role='alert' data-testid={`field-${testid}-error`}>{errorLabel}</ErrorLabel>}
    {extraInfo && <ExtraInfo>{extraInfo}</ExtraInfo>}
  </FormFieldWrapper>
);
