import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ImagePlaceholder: React.FC<IContentLoaderProps> = ({ width, height, ...rest }): React.ReactElement => (
  <LoadingPlaceholder style={{ width: '100%', margin: '0 auto', height: 'auto' }} viewBox={`0 0 ${width} ${height}`} {...rest}>
    <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </LoadingPlaceholder>
);

export default ImagePlaceholder;
