import React from 'react';
import type { CategoryTile as CategoryTileType } from './categoryTile.types';
import { Icon } from '../Icon';
import { TitlePlaceholder, SubtitlePlaceholder, CirclePlaceholder } from './placeholders/categoryTilePlaceholders';

import {
  LinkWrapper,
  IconWrapper,
  TextWrapper,
  Title,
  Subtitle
} from './categoryTile.styles';

/**
 * @param {import('../../../icons').IconsType} icon string indicating icon to show in circle
 * @param {string} title Title (large) text to display
 * @param {string | undefined} [subtitle] Subtitle (small) text to display
 * @param {string | undefined} [link] Link to go to when clicked
 * @param {(_path: string) => void}  [onClick] Action for Tile click
 */

export const CategoryTile = ({ icon, title, subtitle, link, parentIsLoading, testid = 'category-tile', onClick }: CategoryTileType): React.ReactElement => {

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onClick && link) {
      onClick(link);
    }
  };

  if (parentIsLoading) {
    return (
      <LinkWrapper data-testid={testid}>
        <CirclePlaceholder />
        <TextWrapper>
          <Title><TitlePlaceholder /></Title>
          <Subtitle><SubtitlePlaceholder /></Subtitle>
        </TextWrapper>
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper { ...(link ? { href: link } : {}) } onClick={handleClick} tabIndex={0} data-testid={testid}>
      <IconWrapper><Icon glyph={icon} size='lg' /></IconWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TextWrapper>
    </LinkWrapper>
  );
};
