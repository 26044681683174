import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { components } from 'src/lib/api/openapi';
import { Button, DialogBox, Tab } from '@arcade/component-library';

import Loader from 'src/components/Loader/loader';

import { userContext } from '../../context/userContext';
import { OverlayContext } from 'src/context/overlayContext';
import CampaignForm, { type CampaignFormType, type Site } from '../../components/CampaignForm/campaignForm';
import ActionsForm, { ActionsFormType } from '../../components/ActionsForm/actionForm';
import { type ActionFiledsForm } from '../../components/ActionFields/actionFields';
import { apiClient } from 'src/lib/apiConfig';

import { padNumber } from '../../lib/utils';

import PageWrapper from '../../components/PageWrapper/pageWrapper';
import PreviewPanel from '../../components/PreviewPanel/previewPanel';

type Campaign = components['schemas']['Campaign-ace_campaign.read'];

import {
  Wrapper,
  TabsWrapper,
  TabContent,
  FormWrapper,
  ErrorMessage,
  ActionsWrapper,
} from './newCampaign.styles';

const NewCampaign = ():React.ReactElement => {
  const [sites, setSites] = useState<Site[]>([]);
  const [channels, setChannels] = useState<Site[]>([]);
  const [campaignToEdit, setCampaignToEdit] = useState<Campaign>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [iFrameKey, setiFrameKey] = useState<string>(Math.random().toString());
  const [loading, setLoading] = useState<boolean>(false);

  const [tabSelected, setTabSelected] = useState<string>('tab-1');

  const [searchParams] = useSearchParams();
  const { user, accessToken } = useContext(userContext);
  const { showOverlay, hideOverlay } = useContext(OverlayContext);

  useEffect(() => {
    const getSetting = async () => {
      const { data } = await apiClient.settingsApi?.getSetting({ token: accessToken as string });
      if (data) {
        let allChannels:Site[] = [];
        const allSites:Site[] = [];
        data.forEach(site => {
          allSites.push({ code: site.code, name: site.name });
          if (site.channels) {
            const channels = site?.channels.map(channel => ({ code: channel.id, name: channel.title }));
            allChannels = [...allChannels, ...channels];
          }
        });
        if (allChannels.length > 0) {
          const jsonObj = allChannels.map(channel => JSON.stringify(channel));
          const unique = new Set(jsonObj);
          allChannels = Array.from(unique).map(item => JSON.parse(item));
          setChannels(allChannels);
        }
        setSites(allSites);
      }
    };
    getSetting();
    const getCampaign = async (id:string) => {
      const { data, error } = await apiClient.campaignsApi?.getCampaignById({ campaignId: id, token: accessToken as string });
      if (data) {
        setCampaignToEdit(data);
        setLoaded(true);
      }
      if(error) console.error(error);
    };
    const urlCampaignId = searchParams.get('campaignId');
    if (urlCampaignId) {
      getCampaign(urlCampaignId);
    } else {
      setLoaded(true);
    }
  }, []);

  const getObjectFromArray = (arrayToConvert?: string[]) => {
    if(!arrayToConvert || arrayToConvert === undefined) return [];
    const obj:{ [key:string]: boolean} = {};
    for (let i = 0; i < arrayToConvert.length; i++) {
      obj[arrayToConvert[i]] = true;
    }
    return obj;
  };

  const getarrayFromObject = (obj?: { [key:string]: boolean}):string[] => {
    const arr:string[] = [];
    if(!obj) return arr;
    if (obj.all) {
      arr.push('all');
    } else {
      for (const [key, value] of Object.entries(obj)) {
        if (value) arr.push(key);
      }
    }
    return arr;
  };

  const parseDate = (UTCDate:string):string => {
    const utc = new Date(UTCDate);
    return `${utc.getFullYear()}-${padNumber(utc.getMonth() + 1)}-${padNumber(utc.getDate())}T${padNumber(utc.getHours())}:${padNumber(utc.getMinutes())}`;
  };

  const getFormData = (apiData:Campaign):CampaignFormType | undefined => {
    if(!apiData) return undefined;
    return {
      ...apiData,
      ...{
        channels: getObjectFromArray(apiData?.channels),
        sites: getObjectFromArray(apiData?.sites),
        startDate: apiData?.startDate ? parseDate(apiData?.startDate) : undefined,
        endDate: apiData.endDate ? parseDate(apiData.endDate) : undefined,
        actions: apiData?.actions
      }
    } as CampaignFormType;
  };

  const submitData = async (data:CampaignFormType) => {
    setLoading(true);
    const sitesArray = getarrayFromObject(data.sites);
    const channelArray = getarrayFromObject(data.channels);
    const UTCStartDate = data.startDate ? new Date(data.startDate).toUTCString() : undefined;
    const UTCEndDate = data.endDate ? new Date(data.endDate).toUTCString() : undefined;
    const formattedData = Object.assign(
      {},
      data,
      { sites: sitesArray, channels: channelArray, startDate: UTCStartDate, endDate: UTCEndDate });

    if (campaignToEdit) {
      const { data : campaignData, error } = await apiClient.campaignsApi?.updateCampaignById({
        campaignId: campaignToEdit.id as string,
        data: { ...formattedData, ...{ editedBy: user?.nickname } } as any,
        token: accessToken as string
      });
      if (campaignData) {
        setCampaignToEdit(campaignData);
      }
      if (error) setSubmitError('There was a problem updating the campaign.');
      setLoading(false);
    } else {
      const { data : campaignData, error } = await apiClient.campaignsApi?.addCampaigns({ campaign: { ...formattedData, ...{ createdBy: user?.nickname ?? '', status: 'draft' } }, token: accessToken as string });
      if (campaignData) {
        setCampaignToEdit(campaignData);
      }
      if (error) setSubmitError('There was a problem saving the campaign');
      setLoading(false);
    }
    setiFrameKey(Math.random().toString());
  };

  const submitActions = async (data:ActionsFormType) => {
    if (campaignToEdit) {
      setLoading(true);
      const { data: campaignData, error } = await apiClient.campaignsApi?.updateCampaignById({
        campaignId: campaignToEdit.id as string,
        data: { ...campaignToEdit, ...{ editedBy: user?.nickname, actions: data.actions } } as any,
        token: accessToken as string
      });
      if (campaignData) {
        setCampaignToEdit(campaignData);
      }
      if (error) setSubmitError('There was a problem updating the campaign.');
      setLoading(false);
      setiFrameKey(Math.random().toString());
    }
  };

  const getCampaignSites = (sites:Site[]):Site[] => {
    if(!campaignToEdit) return sites;
    const campaignSites = campaignToEdit.sites;
    if(campaignSites?.includes('all')) return sites;
    const filteredSites = [];
    for (let i = 0; i < sites.length; i++) {
      if (campaignSites?.includes(sites[i].code as string)) filteredSites.push(sites[i]);
    }
    return filteredSites;
  };

  const changeStatus = async (status:string):Promise<void> => {
    const { data, error } = await apiClient.campaignsApi.updateCampaignById({
      campaignId: campaignToEdit?.id as string,
      data: {
        status: status,
        editedBy: user?.nickname as string
      } as any,
      token: accessToken as string
    });
    if (data) {
      setCampaignToEdit(data);
      hideOverlay();
    }
    if (error) console.error(error);
  };

  const handlePublish = () => {
    const newStatus = campaignToEdit?.status === 'draft' ? 'published' : 'draft';
    if(newStatus === 'published') {
      showOverlay(
        <DialogBox title='Publish a campaign' description='You are about to publish a campaign. Please make sure the campaign details are correct before publishing.' confirmButtonText='Confirm' declineButtonText='Cancel' onClose={hideOverlay} onConfirm={() => changeStatus(newStatus)} onDecline={hideOverlay} requiredAction/>,
        'Delete Campaign'
      );
    } else {
      changeStatus(newStatus);
    }
  };

  const handleTabClick = (e:React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) : void => {
    setTabSelected(e.currentTarget.id);
  };

  return (
    <PageWrapper title={`Campaign${ campaignToEdit ? ` - ${campaignToEdit.status}` : ''}`}>
      <TabsWrapper>
        <div>
          <Tab id='tab-1' size='small' selected={tabSelected === 'tab-1'} onClick={handleTabClick} label='Settings' />
          <Tab id='tab-2' size='small' selected={tabSelected === 'tab-2'} disabled={!campaignToEdit} onClick={handleTabClick} label='Actions' />
          <Tab id='tab-3' size='small' selected={tabSelected === 'tab-3'} disabled={!campaignToEdit} onClick={handleTabClick} label='Preview' />
        </div>
        <Button onClick={handlePublish} disabled={!campaignToEdit?.id}>{campaignToEdit?.status === 'draft' ? 'Publish campaign' : 'Unpublish campaign'}</Button>
      </TabsWrapper>
      <Wrapper>
        <TabContent selected={tabSelected === 'tab-1'}>
          <FormWrapper>
            {loaded ? <CampaignForm
              formId='campaign-form'
              campaign={getFormData(campaignToEdit as Campaign)}
              sites={sites}
              channels={channels}
              onSubmit={submitData}
            /> : <Loader />}
          </FormWrapper>
          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          <ActionsWrapper>
            <Button type='submit' disabled={loading} form='campaign-form' btnType='outlined'>{loading ? 'Saving' : 'Save'}</Button>
          </ActionsWrapper>
        </TabContent>
        <TabContent selected={tabSelected === 'tab-2'}>
          <FormWrapper>
            <ActionsForm
              formId='actions-form'
              onSubmit={submitActions}
              actionsData={campaignToEdit?.actions as ActionFiledsForm || []}
            />
          </FormWrapper>
          <ActionsWrapper>
            <Button type='submit' disabled={loading} form='actions-form' btnType='outlined'>{loading ? 'Saving' : 'Save'}</Button>
          </ActionsWrapper>
        </TabContent>
        <TabContent selected={tabSelected === 'tab-3'}>
          <PreviewPanel iFrameKey={iFrameKey} sites={getCampaignSites(sites)} campaignId={campaignToEdit?.id} disabled={!campaignToEdit?.id} />
        </TabContent>
      </Wrapper>
    </PageWrapper>
  );
};

export default NewCampaign;
