import { styled } from 'styled-components';

export const SavingPromotion = styled('div').withConfig({
  shouldForwardProp: prop => !['selected'].includes(prop)
}) <{ selected: boolean }>`
  border: 1px solid ${props => props.theme.layout.primary};
  color: ${props => props.theme.layout.primary};
  padding: 2px 7px;
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh0};
  text-transform: ${props => props.theme.fontDecor.fd4};
  white-space: nowrap;
  ${props => props.selected && `background-color: ${props.theme.layout.primary}; color: ${props.theme.colors.white};`}
`;

export const ProductOptionListItemWrapper = styled.div<{ selected: boolean }>`
  box-shadow: 0 0 4px 0 ${props => props.theme.colors.lightGrey};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  ${props => props.selected && `border: 1px solid ${props.theme.layout.primary};`}
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 15px 20px;
  }

  &:hover {
    border: 1px solid ${props => props.theme.layout.primary};
      

    ${SavingPromotion} {
      background-color: ${props => props.theme.layout.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const OptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionListItemTitle = styled.h5`
  font-size: ${props => props.theme.fontSizes.fs4};
  font-weight: ${props => props.theme.fontWeights.fw2};
`;

export const OptionDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  color: ${props => props.theme.colors.darkGrey};
`;

export const Pricing = styled.p`
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
  padding-bottom: 5px;
  padding-left: 0;

  span {
    color: ${props => props.theme.layout.primary};
    font-weight: ${props => props.theme.fontWeights.fw2};
  }

  b {
    font-weight: ${props => props.theme.fontWeights.fw2};
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding-bottom: 0;
  }
`;

export const OriginalPrice = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  text-decoration: line-through;
  padding-right: 8px;
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: calc(${props => props.theme.fontSizes.fs2} - 1px);
  line-height: calc(${props => props.theme.lineHeights.lh2} - 1px);

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: ${props => props.theme.fontSizes.fs2};
    line-height: ${props => props.theme.lineHeights.lh2};
  }
`;

export const SubPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SavingPromotionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-end;
  }
`;

export const FollowOnPricing = styled('div').withConfig({
  shouldForwardProp: prop => !['followOnPrice'].includes(prop)
}) <{ followOnPrice: boolean }>`
  padding-bottom: 22px;
  ${props => props.followOnPrice && 'padding-bottom: 0;'};
`;
