import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const AccordionTitlePlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    width={100}
    height={28}
    {...props}
  >
    <rect x="0" y="6" rx="0" ry="0" width="100" height="16" />
  </LoadingPlaceholder>
);

export default AccordionTitlePlaceholder;
