import React from 'react';
import { Controller, FieldArrayWithId, type Control } from 'react-hook-form';

import BaseAction from './baseAction';
import { FormField, TextBox } from '@arcade/component-library';

import ActionField from './actionField';

import type { CampaignFormType } from '../CampaignForm/campaignForm';

const BannerActionField = ({ control, index, field, errors, onRemove } : {
  control: Control<CampaignFormType>,
  index: number,
  field: FieldArrayWithId,
  errors: any,
  onRemove: (_i:number) => void
}):React.ReactElement => {
  return (
    <ActionField onRemove={() => onRemove(index)} actionName='Modify Banner'>
      <BaseAction {...{ control, index, field, errors }} />
      <Controller
        name={`actions.${index}.position`}
        control={control}
        rules={{
          required: 'Please enter a position'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-position`}
            label='Position'
            errorLabel={errors?.actions?.index?.position?.message || ''}
            required
          >
            <TextBox {...field} id={`action-${index}-position`} state={errors?.actions?.index?.position ? 'error' : 'normal'} />
          </FormField>
        )}
      />
    </ActionField>
  );
};

export default BannerActionField;
