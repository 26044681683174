import { styled, css } from 'styled-components';

export const DescriptionPlaceholderWrapper = styled.div`
  ${({ theme }) => css`
    svg {
      height: ${theme.lineHeights.lh1};
      width: auto;
    }
  `}
`;

export const DeliveryEstimatePlaceholderWrapper = DescriptionPlaceholderWrapper;
export const TotalPlaceholderWrapper = DescriptionPlaceholderWrapper;
export const PricePlaceholderWrapper = DescriptionPlaceholderWrapper;
export const IssuePricingPlaceholderWrapper = styled.div`
  ${({ theme }) => css`
    svg {
      height: ${theme.lineHeights.lh0};
      width: auto;
    }
  `}

`;
export const RemovePlaceholderWrapper = DescriptionPlaceholderWrapper;
export const SubscriptionTypePlaceholderWrapper = styled.div`
  ${({ theme }) => css`
    svg {
      height: clamp(${theme.lineHeights.lh3}, 3vw, ${theme.lineHeights.lh4});
      width: auto;
    }
  `}
`;
