import React from 'react';
import type { ProductOptionTab as ProductOptionTabProps } from './productOptionTab.types';
import ProductOptionTabPlaceholder from './placeholders/productOptionTabPlaceholder';

import {
  ProductOptionTabWrapper,
  ProductContentWrapper,
  ProductOptionTabTitle,
  Description,
  Pricing,
  Signpost
} from './productoptionTab.styles';
/**
 *
 * @param {string} title Option's title.
 * @param {string} price Option's price.
 * @param {string} [priceUnit] Option's price label.
 * @param {string} description Option's description.
 * @param {string} [signpost] Additional incentive.
 * @param {boolean} [selected=false] If the tab is selected.
 * @param {() => void} onClick Tab click callback.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading
 *
 */

export const ProductOptionTab = ({ title, price, priceUnit, description, signpost, selected=false, onClick, text, parentIsLoading = false }: ProductOptionTabProps): React.ReactElement => {
  return (
    <ProductOptionTabWrapper onClick={onClick} data-testid='option' hasSignpost={!!signpost}>
      {signpost && <Signpost selected={selected} data-testid='sign-post'> {signpost} </Signpost>}
      <ProductContentWrapper selected={selected} hasSignpost={!!signpost} data-testid='select-option'>
        {parentIsLoading ? (
          <ProductOptionTabPlaceholder />
        ):(
          <>
            {title && <ProductOptionTabTitle> {title}  </ProductOptionTabTitle>}
            {price && text && priceUnit && <Pricing>{price && `${text} ${price} / ${priceUnit}`}</Pricing>}
            {description && <Description>{description} </Description>}
          </>
        )}
      </ProductContentWrapper>
    </ProductOptionTabWrapper>
  );
};
