import { styled, css } from 'styled-components';

export const ProductOptionListTitlePlaceholderWrapper = styled.div`
  margin-bottom: 15px;

  svg {
    ${props => css`
      height: clamp(${props.theme.lineHeights.lh2}, 3vw, ${props.theme.lineHeights.lh3});
    `}
    width: auto;
  }
`;
