import { styled } from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const LoginBox = styled.div`
  display: inline-block;
  text-align: center;
  padding: 50px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 10px;
  filter: drop-shadow(0 0 4px ${props => props.theme.colors.lightGrey});
  margin-bottom: 50px;

  p {
    padding-bottom: 20px;
    color: ${props => props.theme.colors.darkGrey};
  }
`;
