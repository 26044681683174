import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ProductListItemDescriptionPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    width={900}
    height={84}
    {...props}
  >
    <rect x="0" y="6" rx="0" ry="0" width="900" height="16" />
    <rect x="0" y="34" rx="0" ry="0" width="900" height="16" />
    <rect x="0" y="62" rx="0" ry="0" width="180" height="16" />
  </LoadingPlaceholder>
);

export default ProductListItemDescriptionPlaceholder;
