import React from 'react';

import TitlePlaceholder from './placeholders/titlePlaceholder';
import type { Title as TitleProps } from './title.types';

import {
  TitleWrapper
} from './title.styles';

/**
 *
 * @param {string} text Heading text.
 * @param {import('./title.types').HeadingTypes} [tag=h2] Heading tag (h1, h2, h3, etc...).
 * @param {string} [id] Heading id
 * @param {string} [extraClass] Additional class to be assigned to the heading.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading
 *
 */

export const Title = ({ text, tag = 'h2', id, extraClass, parentIsLoading = false }:TitleProps):React.ReactElement => {
  return (
    parentIsLoading ? (
      <TitlePlaceholder titleTag={tag} />
    ) : (
      <TitleWrapper dangerouslySetInnerHTML={{ __html: `<${tag} class="${extraClass || tag}" id="${id || ''}">${text}</${tag}>` }}/>
    )
  );
};
