import React, { useState, useEffect, useRef } from 'react';

import { FormField, DropDown } from '@arcade/component-library';
import type { Site } from '../CampaignForm/campaignForm';
import { siteUrls } from '../../lib/sites';

import {
  PreviewWrapper,
  PanelControls,
  DropWrapper,
  PanelPreview,
  PanelIframe
} from './previewPanel.styles';

const PreviewPanel = ({ sites, campaignId, disabled, iFrameKey }: { sites: Site[], campaignId?:string, disabled:boolean, iFrameKey:string }):React.ReactElement => {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [dropOptions, setDropOptions] = useState<{ label:string, value:string }[]>([]);
  const iframe = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const options = sites.map(site => ({ label: site.name || '', value: site.code || '' }));
    setDropOptions(options);
  }, [sites]);

  const handleSiteSelection = (event: React.ChangeEvent<HTMLSelectElement>):void => {
    const dropValue = event.target.value;
    const urlObj = siteUrls[dropValue];
    const baseUrl = process.env.NODE_ENV !== 'production' && urlObj.stagingUrl ? urlObj.stagingUrl : urlObj.baseUrl;
    const url = `${baseUrl}${urlObj.pageUrl}`;
    setPreviewUrl(url);
  };

  const sendMessage = (id?:string) => {
    if(iframe.current && id) {
      setTimeout(() => {
        iframe?.current?.contentWindow?.postMessage({ campaignId: id, secret: process.env.NEXTJS_DRAFT }, '*');
      }, 1000);
    }
  };

  return (
    <PreviewWrapper>
      <PanelControls>
        <DropWrapper>
          <FormField id='site-dropdown' label='Preview Site' required>
            {dropOptions.length > 0 && <DropDown testid='site-select' id='site-dropdown' name='preview-site' placeholder='Please select a site' options={dropOptions} onChange={handleSiteSelection} disabled={disabled}/>}
          </FormField>
        </DropWrapper>
      </PanelControls>
      {previewUrl && <PanelPreview>
        <PanelIframe key={iFrameKey} ref={iframe} title='preview' src={previewUrl} onLoad={() => sendMessage(campaignId)} />
      </PanelPreview>}
    </PreviewWrapper>
  );
};

export default PreviewPanel;
