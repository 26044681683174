import React, { useMemo } from 'react';
import type { CheckoutActions as CheckoutActionsProps } from './checkoutActions.types';

import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';

import {
  ActionsWrapper,
  ActionItem
} from './checkoutActions.styles';

/**
 *
 * @param {import('./checkoutActions.types').Option[]} [options] Methods of payment/checkout.
 * @param {(_option:string) => void} onOptionSelect Callback on checkout method pressed.
 * @param {boolean} disabled Set the button state.
 * @param {boolean} [outOfStock = false] Indicates if out of stock
 *
 */

const giveAddToBasketText = (noStock?: boolean, addingBasket?: boolean) => {
  if (noStock) {
    return 'Out of stock';
  }
  if (addingBasket) {
    return 'Adding to basket...';
  }
  return 'Add to basket';
};

export const CheckoutActions = ({ options, onOptionSelect, testid = 'checkoutActions', disabled, outOfStock = false, addingToBasket = false, id }:CheckoutActionsProps):React.ReactElement => {
  if(!options || options.length === 0) return <div/>;

  const addToBasketText = useMemo(() => giveAddToBasketText(outOfStock, addingToBasket), [outOfStock, addingToBasket]);

  const getOptionButton = (opt:string):React.ReactElement => {
    switch (opt) {
      case 'paypal':
        return <Button testid={`${testid}-paypal`} btnType='outlined' responsive onClick={() => onOptionSelect(opt)} disabled={disabled}>Checkout with&nbsp;<Icon glyph='paypal_logo' width='69px' /></Button>;
      default:
        return <Button testid={`${testid}-basket`} btnType='filled' responsive size='large' onClick={() => onOptionSelect(opt)} disabled={disabled}>{addToBasketText}</Button>;
    }
  };

  return (
    <ActionsWrapper data-testid={testid} {...(id ? { id: id } : {})}>
      {options.map(option => (
        <ActionItem key={option}>{getOptionButton(option)}</ActionItem>
      ))}
    </ActionsWrapper>
  );
};
