import React, { forwardRef, ForwardedRef, useState } from 'react';

import type { Radio as RadioProps } from './radio.types';

import {
  RadioContainer,
  RadioHidden,
  RadioWrapper,
  LabelText
} from './radio.styles';

export const Radio = forwardRef((props: RadioProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { id, label, testid = 'radio', checked, name, value, className = '' } = props;

  const [focused, setFocused] = useState<boolean>(false);

  const disabled = props.disabled || false;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    const { onChange } = props;

    if (onChange) onChange(event);

  };

  const onFocus = (): void => {
    setFocused(true);
  };

  const onBlur = (): void => {
    setFocused(false);
  };

  return (
    <RadioContainer className={className}>
      <RadioHidden
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        ref={ref}
        data-testid={testid}
      />

      <RadioWrapper className={className ? `${className}-radio` : ''} disabled={disabled} focused={focused} checked={checked || false} />

      {label ? <LabelText disabled={disabled} dangerouslySetInnerHTML={{ __html: label }}/> : null}
    </RadioContainer>
  );
});
