import { styled } from 'styled-components';

export const StepsWrapper = styled.ol`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: ${props => `1px solid ${props.theme.layout.secondary}`};
  min-height: 48px;

  @media (min-width: ${props => `${props.theme.breakpoints.mobileL}`}) {
    min-height: 32px;
  }
`;

export const StepsItem = styled('li').withConfig({
  shouldForwardProp: props =>
    !['current', 'completed'].includes(props)
})<{ current: boolean, completed: boolean }>`
  display: table;
  color: ${props => `${props.theme.colors.black}`};
  background-color: ${props => `${props.theme.colors.white}`};
  position: relative;
  padding: 0 10px;

  @media (min-width: ${props => `${props.theme.breakpoints.mobileL}`}) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  ${props => props.current && `
    background-color:  ${props.theme.layout.secondary};

    &::before {
      top: 0;
      left: 0;
      border-width: 24px 0 24px 16px;
      border-style: solid;
      border-color: ${props.theme.layout.secondary} ${props.theme.colors.white};

      @media (min-width: ${props.theme.breakpoints.mobileL}) {
        border-width: 16px 0 16px 18px;
      }
    }

    &::after {
      top: 0;
      right: 0;
      border-width: 24px 0 24px 16px;
      border-style: solid;
      border-color: ${props.theme.colors.white} transparent;

      @media (min-width: ${props.theme.breakpoints.mobileL}) {
        border-width: 16px 0 16px 18px;
      }
    }
  `}

  ${props => props.completed && `
    svg {
      display: inline;
      margin-right: 5px;
      margin-top: -5px;
      color: ${props.theme.colors.green};
    }
  `}
`;

export const StepsContent = styled.span`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;

  @media (min-width: ${props => `${props.theme.breakpoints.mobileL}`}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
