import { styled, css } from 'styled-components';
import { hideVisually } from 'polished';

export const RadioContainer = styled.label`
  cursor: pointer;
  display: flex;
`;

export const RadioHidden = styled.input.attrs({ type: 'radio' })`
  ${hideVisually()}
`;

export const RadioStyles = css<{ disabled?: boolean, focused?: boolean, checked?: boolean }>`
  width: 18px;
  height: 18px;
  border: 1px solid ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  border-radius: 9px;
  position: relative;
  margin-top: 3px;
  ${props => props.focused && css`
    border-color: ${props.theme.colors.focus};
  `}

  &::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    top: -2px;
    left: -2px;
    border-radius: 10px;
    ${props => props.focused && css`
      border: 1px solid ${props => props.theme.colors.focus};
    `}
  }

  ${props => props.checked && css`
    background-color: ${props.theme.colors.black};

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      background-color: ${props.theme.colors.white};
      border-radius: 5px;
    }
  `}
`;

export const RadioWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['focused', 'checked', 'disabled'].includes(props)
})<{ disabled: boolean, focused: boolean, checked: boolean }>`
  ${RadioStyles}
`;

export const LabelText = styled.span<{ disabled: boolean}>`
  vertical-align: top;
  padding-left: 13px;
  width: calc(100% - 34px);
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
  ${props => props.disabled && css`
    color: ${props => props.theme.colors.grey};
    cursor: default;
  `}
`;
