import { fontBasic, fontFeature, fontStyle } from './mixins';

export const deviceBreakpointSizes = {
  desktop: '1100px',
  laptop: '1024px',
  tablet: '768px',
  tabletS: '700px',
  mobileL: '650px',
  mobile: '438px'
};

export const borderRadiuses = {
  br0: '0.250rem', // 4px
  br1: '0.4rem', // 6px
  br2: '0.625rem', // 10px
  br3: '1.188rem', // 19px
  br4: '1.875rem', // 30px
  br5: '3.125rem' // 50px
};

export const colors = {
  white: 'rgba(255,255,255,1)', // #FFF
  black: 'rgba(27,27,27,1)', // #1B1B1B
  red: 'rgba(167,25,48,1)', // #A71930
  deepRed: 'rgba(117, 17, 35, 1)',
  lightBlue: 'rgba(234,241,252,1)',
  darkGrey: 'rgba(102,102,102,1)', // #666666
  grey: 'rgba(204,204,204,1)',
  lightGrey: 'rgba(237,237,237,1)', // #EDEDED
  lightestGrey: 'rgba(245,245,245,1)', // #F5F5F5
  lightBeige: 'rgba(247,246,242,1)', // #F7F6F2
  beige: 'rgba(213,210,202,1)', // #D5D2CA
  error: 'rgba(222,33,33,1)',
  errorLight: 'rgba(245,204,198,1)',
  success: 'rgba(15,133,98,1)',
  successLight: 'rgba(190,223,213,1)',
  warning: 'rgba(255,193,32,1)',
  warningLight: 'rgba(255,224,143,1)',
  focus: 'rgba(46,114,230,1)',
};

export const spacerSizes = {
  ss0: '0.125rem', // 2px
  ss1: '0.313rem', // 5px
  ss2: '0.625rem', // 10px
  ss3: '0.938rem', // 15px
  ss4: '1.25rem', // 20px
  ss5: '2rem', // 32px
  ss6: '3.125rem', // 50px
  ss7: '4.375rem' // 70px
};

export const fontSizes = {
  fs0: '0.750rem', // 12px
  fs1: '0.875rem', // 14px
  fs2: '1.000rem', // 16px
  fs3: '1.125rem', // 18px
  fs4: '1.250rem', // 20px
  fs5: '1.500rem', // 24px
  fs6: '2.125rem' // 34px
};

export const fontWeights = {
  fw0: '200', // light
  fw1: '400', // regular
  fw2: '600', // semi-bold
  fw3: '700', // bold
  fw4: '900' //  extra-bold
};

export const lineHeights = {
  lh0: '1.125rem', // 18px
  lh1: '1.313rem', // 21px
  lh2: '1.500rem', // 24px
  lh3: '1.625rem', // 26px
  lh4: '1.750rem', // 28px
  lh5: '2.125rem', // 34px
  lh6: '2.875rem' // 46px
};

export const fontDecor = {
  fd0: 'none',
  fd1: 'capitalise',
  fd2: 'italic',
  fd3: 'lowercase',
  fd4: 'uppercase',
  fd5: 'line-through',
  fd6: 'underline',
  fd7: 'overline'
};

const xSmall = fontBasic(fontSizes.fs0,lineHeights.lh0);

const xSmallFeature = `
  ${fontFeature(fontSizes.fs0,spacerSizes.ss0,lineHeights.lh0)}
  ${fontStyle(fontDecor.fd0,fontDecor.fd4,fontWeights.fw1)}
`;

const small = `
  ${fontBasic(
    `clamp(${fontSizes.fs0}, 3vw, ${fontSizes.fs1})`,
    `clamp(${lineHeights.lh0}, 3vw, ${lineHeights.lh1})`
  )}
`;

const smallFeature = `
  ${fontFeature(
    `clamp(${fontSizes.fs0}, 3vw, ${fontSizes.fs1})`,
    spacerSizes.ss0,
    `clamp(${lineHeights.lh1}, 3vw, ${lineHeights.lh3})`
  )}
  ${fontStyle(fontDecor.fd0,fontDecor.fd4,fontWeights.fw1)}
`;

const body = `
  ${fontBasic(
    `clamp(${fontSizes.fs1}, 3vw, ${fontSizes.fs2})`,
    `clamp(${lineHeights.lh3}, 3vw, ${lineHeights.lh4})`
  )}
`;

const medium = `
  ${fontBasic(
    `clamp(${fontSizes.fs2}, 3vw, ${fontSizes.fs3})`,
    `clamp(${lineHeights.lh2}, 3vw, ${lineHeights.lh3})`
  )}
`;

const mediumFeature = `
  ${fontFeature(
    `clamp(${fontSizes.fs2}, 3vw, ${fontSizes.fs3})`,
    spacerSizes.ss0,
    `clamp(${lineHeights.lh2}, 3vw, ${lineHeights.lh3})`
  )}
  ${fontStyle(fontDecor.fd0,fontDecor.fd4,fontWeights.fw1)}
`;

const large = `
  ${fontBasic(
    `clamp(${fontSizes.fs3}, 3vw, ${fontSizes.fs4})`,
    `clamp(${lineHeights.lh3}, 3vw, ${lineHeights.lh4})`
  )}
`;

const largeFeature = `
  ${fontFeature(
    `clamp(${fontSizes.fs3}, 3vw, ${fontSizes.fs4})`,
    spacerSizes.ss0,
    `clamp(${lineHeights.lh3}, 3vw, ${lineHeights.lh4})`
  )}
  ${fontStyle(fontDecor.fd0,fontDecor.fd4,fontWeights.fw1)}
`;

const xLarge = `
  ${fontBasic(
    `clamp(${fontSizes.fs4}, 3vw, ${fontSizes.fs5})`,
    `clamp(${lineHeights.lh4}, 3vw, ${lineHeights.lh5})`
  )}
`;

const xxLarge = `
  ${fontBasic(
    `clamp(${fontSizes.fs5}, 3vw, ${fontSizes.fs6})`,
    `clamp(${lineHeights.lh5}, 3vw, ${lineHeights.lh6})`
  )}
`;

export const headings = {
  xSmall,
  xSmallFeature,
  small,
  smallFeature,
  body,
  medium,
  mediumFeature,
  large,
  largeFeature,
  xLarge,
  xxLarge
};

export const zIndexes = {
  zi0: 0,
  zi1: 99,
  zi2: 999,
  zi3: 9999,
  zi4: 99999,
};

export const layout = {
  primary: colors.red,
  secondary: 'rgba(217,217,217,1)',
  tertiary: colors.black,
  header: {
    mode: 'light',
    background: colors.white,
    searchBorder: colors.lightGrey,
    searchFill: colors.lightestGrey
  },
  footer: {
    mode: 'light',
    background: colors.lightBeige
  },
  footerCopy: {
    mode: 'dark',
    background: colors.black
  }
};

export const baseTheme = {
  name: 'default',
  breakpoints: deviceBreakpointSizes,
  borderRadiuses,
  spacerSizes,
  fontSizes,
  fontWeights,
  fontDecor,
  lineHeights,
  headings,
  colors,
  zIndexes,
  layout
};

export const theme = baseTheme;

export type Theme = typeof baseTheme;

export type Layout = typeof layout;
