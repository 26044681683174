import { styled } from 'styled-components';

export const SideMenuWrapper = styled.div`
  overflow-x: auto;
  box-sizing: border-box;
  padding: 20px;
  width: 250px;
  height: 100vh;
  background-color: ${props => props.theme.colors.white};
  border-right: 3px solid ${props => props.theme.colors.lightGrey};
  filter: drop-shadow(0 0 2px ${props => props.theme.colors.lightGrey});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MenuWrapper = styled.div`
  margin-top: 40px;
`;

export const MenuTitle = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  text-transform: uppercase;
  color: ${props => props.theme.colors.grey};
  padding-left: 15px;
`;

export const MenuList = styled.ul`
  padding: 0;
  list-style: none;
`;

export const MenuLink = styled.li`

  a {
    color: ${props => props.theme.colors.darkGrey};
    display: block;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 10px;

    span {
      padding-left: 10px;
    }

    &:hover,
    &.active {
      color: ${props => props.theme.layout.primary};
      background-color: ${props => props.theme.layout.tertiary};
    }
  }
`;
