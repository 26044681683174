import createClient from 'openapi-fetch/dist/index.min';

import type { paths } from './api/openapi';
import { apiUrlByEnv } from '../config/index';

import { SettingsApi } from './api/apis/SettingsApi';
import { CampaignsApi } from './api/apis/CampaignsApi';

export const client = createClient<paths>({
  baseUrl: apiUrlByEnv,
  credentials: 'include'
});

export const apiClient = {
  settingsApi: SettingsApi(client),
  campaignsApi: CampaignsApi(client)
};

export type Client = typeof client;
