import { styled } from 'styled-components';

export const OrderSummaryWrapper = styled.div`
  box-shadow: 0 0 4px ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.white};
`;

export const ContentWrapper = styled.div`
  padding: ${props => props.theme.spacerSizes.ss4};
`;

export const ExtraContentWrapper = styled(ContentWrapper)`
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const SummaryContent = styled.div`
  padding: ${props => props.theme.spacerSizes.ss4} 0 0;
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${props => props.theme.spacerSizes.ss3};

  > span > svg {
    color: ${props => props.theme.colors.red};
    margin-bottom: -2px;
  }
`;

export const SummaryRed = styled(Summary)`
  color: ${props => props.theme.colors.red};
`;

export const SummaryTotal = styled(Summary)`
  border-top: 1px solid;
  border-color: ${props => props.theme.colors.lightGrey};
  font-weight: ${props => props.theme.fontWeights.fw3};
  margin-top: ${props => props.theme.spacerSizes.ss2};
  padding: ${props => props.theme.spacerSizes.ss3} 0;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid;
  border-color: ${props => props.theme.colors.lightGrey};
  padding: ${props => props.theme.spacerSizes.ss3};
`;

export const ChildrenWrapper = styled.div`
  padding-top: ${props => props.theme.spacerSizes.ss4};

  svg {
    width: ${props => props.theme.fontSizes.fs1};
    color: ${props => props.theme.colors.white};
  }
`;
