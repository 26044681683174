import { styled } from 'styled-components';

export const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  button {
    padding: 12px 20px;
  }
`;

export const BtnContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding-right: 10px;
  }
`;

export const MenuItemContainer = styled.div`
  display: block;
`;

export const MenuItemsWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['position'].includes(props)
})<{ position: string }>`
  position: absolute;
  display: block;
  width: 280px;
  top: 55px;
  z-index: ${props => props.theme.zIndexes.zi3};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 20px 15px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0 4px ${props => props.theme.colors.lightGrey};
  box-sizing: border-box;
  ${props => props.position === 'left' ? 'left: 0;' : 'right: 0;'}
`;
