import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Icons from '../../../icons';

import { Icon as IconProps } from './icon.types';

/**
 * Render an `<svg>` element
 *
 * @param {import('../../../icons').IconsType} props.glyph Glyph to show in the SVG.
 * @param {string} props.title Title to be added to the SVG for accessibility.
 * @param {string} props.width Width to be passed to the SVG.
 * @param {string} props.height Height to be passed to the SVG.
 * @param {string} props.fill Fill colour to be passed to the SVG.
 */

export const Icon = ({ glyph, title, testid = 'icon', ...rest }:IconProps):React.ReactElement => {
  const Svg = Icons[glyph.toUpperCase()];

  return <FontAwesomeIcon data-testid={testid} icon={Svg} title={title || `${glyph.replace(/_/g, ' ')} icon`} {...rest} />;
};
