import { styled, css } from 'styled-components';

export const SideMenuWrapper = styled('div').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  box-sizing: border-box;
  padding: 20px 0;
  width: ${props => props.expanded ? '250px' : '80px'};
  height: 100vh;
  background-color: ${props => props.theme.colors.white};
  border-right: 3px solid ${props => props.theme.colors.lightGrey};
  filter: drop-shadow(0 0 2px ${props => props.theme.colors.lightGrey});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const MenuToggle = styled('button').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  position: absolute;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.white};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  filter: drop-shadow(0 0 2px ${props => props.theme.colors.lightGrey});
  top: 90px;
  left: ${props => props.expanded ? '205px' : '60px'};
  z-index: 9999;
  cursor: pointer;

  svg {
    ${props => props.expanded && 'transform: rotate(180deg);'}
  }
`;

export const LogoWrapper = styled('div').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.expanded && css`
    width: calc(100% - 20px);
    justify-content: start;
    margin-left: 20px;
  `}
`;

export const MenuText = styled('span').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  display: ${props => props.expanded ? 'block' : 'none'};
  padding-left: 10px;
`;

export const MenuWrapper = styled('div').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  margin: 40px auto 0;
  text-align: ${props => props.expanded ? 'left' : 'center'};
  ${props => props.expanded && css`
    margin-left: 20px;
  `}
`;

export const MenuTitle = styled('p').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  font-size: ${props => props.theme.fontSizes.fs0};
  text-transform: uppercase;
  color: ${props => props.theme.colors.grey};
  ${props => props.expanded && css`
    margin-left: 10px;
  `}
`;

export const MenuList = styled.ul`
  padding: 0;
  list-style: none;
  align-items: center;
`;

const MenuLinkAnchor = css`
  padding: 0;
  margin: 0;
  display: inline-block;

  a {
    color: ${props => props.theme.colors.darkGrey};
    display: flex;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    align-items: center;

    &:hover,
    &.active {
      color: ${props => props.theme.layout.primary};
      background-color: ${props => props.theme.layout.tertiary};
    }
  }
`;

export const MenuLink = styled.li`
  ${MenuLinkAnchor}
`;

export const LogOutLink = styled('div').withConfig({
  shouldForwardProp: prop =>
    !['expanded'].includes(prop)
})<{ expanded: boolean}>`
  ${MenuLinkAnchor}
  ${props => props.expanded && css`
    text-align: left;
    margin-left: 20px;
    width: calc(100% - 20px);
  `}
`;
