import { styled, css } from 'styled-components';

export const ProductContentWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['hasSignpost'].includes(props)
})<{ selected: boolean, hasSignpost: boolean }>`
  border: 1px solid ${props => props.theme.colors.beige};
  ${props => props.selected && `border: 1px solid ${props.theme.layout.primary}`};
  box-sizing: border-box;
  transition: ease-in-out;
  padding: 20px;
  margin-bottom: 5px;
  box-shadow: 0 0 2px 0 ${props => props.theme.colors.lightGrey};
  border-radius: 10px;
  text-align: left;

  ${props => props.hasSignpost && css`
    border-top-left-radius: 0;
  `}
`;

export const ProductOptionTabTitle = styled.div`
  text-align: left;
  padding-bottom: 3px;
  line-height: ${props => props.theme.lineHeights.lh4};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs4};
`;

export const Pricing = styled.p`
  text-align: left;
  padding-bottom: 15px;
  min-height: 33px;
  font-size: ${props => props.theme.fontSizes.fs0};
  color: ${props => props.theme.colors.darkGrey};
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw1};
`;

export const Description = styled.p`
  line-height: ${props => props.theme.lineHeights.lh1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs1};
  min-height: 40px;
  text-align: left;
`;

export const Signpost = styled.div<{ selected: boolean }>`
  width: fit-content;
  text-align: left;
  padding: 3px 20px;
  text-transform: uppercase;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs0};
  ${props => props.selected === true
    ? `
    background-color: ${props.theme.layout.primary};
    color: ${props.theme.colors.white};
    ` : `
    background-color: ${props.theme.colors.beige};
    color: ${props.theme.colors.black};
    `}
`;

export const ProductOptionTabWrapper = styled('button').withConfig({
  shouldForwardProp: props =>
    !['hasSignpost'].includes(props)
})<{ hasSignpost: boolean }>`
  cursor: pointer;
  position: relative;
  background: none;
  padding: 0;
  border: 0;
  width: 100%;
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};

  &:hover ${ProductContentWrapper} {
    border: 1px solid ${props => props.theme.layout.primary};
  }

  &:hover ${Signpost} {
    background-color: ${props => props.theme.layout.primary};
    color: ${props => props.theme.colors.white};
  }
`;
