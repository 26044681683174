import { styled } from 'styled-components';

import { fontBasic, fontStyle } from '../../../styles/mixins';

export const BlockListWrapper = styled.div`
  text-align: center;

  h2 {
    margin-bottom: calc(${props => props.theme.spacerSizes.ss5} - 1px);
    font-weight: ${props => props.theme.fontWeights.fw2};

    @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
      margin-bottom: calc(${props => props.theme.spacerSizes.ss5} - 2px);
    }
  }
`;

export const BlockListDiv = styled.div`
  padding-top: ${props => props.theme.spacerSizes.ss1};
  padding-bottom: ${props => props.theme.spacerSizes.ss1};

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-top: ${props => props.theme.spacerSizes.ss2};
    padding-bottom: ${props => props.theme.spacerSizes.ss3};
  }

  svg {
    color: ${props => props.theme.layout.primary};
    border: 1px solid ${props => props.theme.colors.black};
    border-radius: 50%;
    padding: ${props => props.theme.spacerSizes.ss2};
    width: 20px;
    height: 20px;

    @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
      padding: ${props => props.theme.spacerSizes.ss3};
      width: 30px;
      height: 30px;
    }
  }
`;

export const BlockListText = styled.p`
  ${props => fontBasic(
    `${props.theme.fontSizes.fs2}`,
    `${props.theme.lineHeights.lh2}`,
  )}

  ${props => fontStyle(
    `${props.theme.fontDecor.fw0}`,
    `${props.theme.fontDecor.fw0}`,
    `${props.theme.fontWeights.fw2}`,
  )}
`;
