import { styled, css } from 'styled-components';
import { transparentize } from 'polished';
import { RadioStyles } from '../../atoms/Radio/radio.styles';

export const SavingPromotion = styled('div').withConfig({
  shouldForwardProp: prop => !['selected', 'inStock'].includes(prop)
}) <{ selected: boolean, inStock: boolean }>`
  border: 1px solid ${props => props.theme.layout.primary};
  color: ${props => props.theme.layout.primary};
  padding: 2px 7px;
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh0};
  text-transform: ${props => props.theme.fontDecor.fd4};
  white-space: nowrap;
  ${props => props.selected && `background-color: ${props.theme.layout.primary}; color: ${props.theme.colors.white};`}
  ${props => props.inStock === false && css`
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.darkGrey};
    border: 1px solid ${props => props.theme.layout.darkGrey};
  `}
`;

export const ProductOptionListItemWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['selected', 'inStock'].includes(props)
})<{ selected: boolean, inStock: boolean }>`
  box-shadow: 0 0 4px 0 ${props => props.theme.colors.lightGrey};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  ${props => props.selected && props.inStock && `border: 1px solid ${props.theme.layout.primary};`}
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 15px 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    &:hover {
      border: 1px solid ${props => props.theme.layout.primary};

      ${SavingPromotion} {
        background-color: ${props => props.theme.layout.primary};
        color: ${props => props.theme.colors.white};
      }
    }
  }

  ${props => props.inStock === false && css`
    cursor: not-allowed;
    pointer-events: none;
  `}

  ${props => props.selected && css`
    background-color: ${props => transparentize('0.95', props.theme.layout.primary)};
  `}
`;

export const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 33px;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    margin-left: 0;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const RadioButton = styled('span').withConfig({
  shouldForwardProp: props =>
    !['focused', 'checked', 'disabled'].includes(props)
})<{ disabled?: boolean, focused?: boolean, checked?: boolean }>`
  ${RadioStyles}
  min-width: 18px;

  ${props => props.checked && css`
    border-color: ${props => props.theme.layout.primary};
    background-color: ${props => props.theme.layout.primary};
  `}
`;

export const OptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionListItemTitle = styled('h5').withConfig({
  shouldForwardProp: props => !['inStock'].includes(props)
})<{ inStock: boolean}>`
  font-size: ${props => props.theme.fontSizes.fs4};
  font-weight: ${props => props.theme.fontWeights.fw2};

  ${props => props.inStock === false && css`
    color: ${props => props.theme.colors.darkGrey};
  `}
`;

export const OptionDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  color: ${props => props.theme.colors.darkGrey};
  margin-bottom: 7px;
`;

export const Pricing = styled('p').withConfig({
  shouldForwardProp: props => !['inStock'].includes(props)
})<{ inStock: boolean }>`
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
  padding-bottom: 5px;
  padding-left: 0;
  color: ${props => props.inStock === false ? props.theme.colors.darkGrey : 'inherit'};

  span,
  b {
    color: ${props => props.inStock === false ? props.theme.colors.darkGrey : props.theme.layout.primary};
    font-weight: ${props => props.theme.fontWeights.fw2};
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding-bottom: 0;
  }
`;

export const OriginalPrice = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  text-decoration: line-through;
  padding-right: 8px;
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: calc(${props => props.theme.fontSizes.fs2} - 1px);
  line-height: calc(${props => props.theme.lineHeights.lh2} - 1px);

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: ${props => props.theme.fontSizes.fs2};
    line-height: ${props => props.theme.lineHeights.lh2};
  }
`;

export const SubPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SavingPromotionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-end;
  }
`;

export const FollowOnPricing = styled('div').withConfig({
  shouldForwardProp: prop => !['followOnPrice'].includes(prop)
}) <{ followOnPrice: boolean }>`
  padding-bottom: 22px;
  ${props => props.followOnPrice && 'padding-bottom: 0;'};

  span,
  b {
    color: ${props => props.theme.layout.primary};
    font-weight: ${props => props.theme.fontWeights.fw2};
  }
`;

export const InStockBoldText = styled.p`
  color: ${props => props.theme.colors.red};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  padding-top: 5px;
`;
