import React from 'react';
import { ProductImage, FreeGift } from '../../atoms';

import {
  ProductTile as ProductTileProps,
} from './productTile.types';

import {
  TileWrapper,
  TileContent,
  TileTitle,
  TileLink,
  TilePrice,
  TileSubtitle,
  AdditionalText,
  TileStrikethrough,
  TileTextSubtitle,
  FlagString,
  GiftFlag,
  PriceText,
} from './productTile.styles';
import { ImageSrc } from 'src/components/atoms/ProductImage/productImage.types';

/**
 *
 * @param {string} title Provides a product title.
 * @param {string} [promotionalText] Promotional text.
 * @param {string} [offerText] Offer text.
 * @param {string} [priceText] Price text.
 * @param {string} [additionalText] Additional text.
 * @param {{ type:string, baseUrl:string, path:string }} image Provides an image object.
 * @param {boolean} hasQuickView Display choice.
 * @param {() => void} onClick Callback on link click.
 * @param {() => void} [handleQuickView] Callback for the quick view button.
 */

export const ProductTile = ({ title, images, onClick, testid = 'product-tile', id, priceText, subtitle, priceTextStrikethrough, priceTextSubtitle, flagString, giftFlag }: ProductTileProps): React.ReactElement => {

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent):void => {
    event.preventDefault();
    onClick();
  };

  return (
    <TileWrapper data-testid={testid} {...(id ? { id } : {})}>
      <div>
        <TileLink href={title} role="button" onClick={handleClick} data-testid={`${testid}_link`}>
          {<ProductImage
            image={images ? images[0] as ImageSrc : undefined}
            title={`${title} Cover`}
            variants={[
              {
                imgWidth: 250,
                mediaMinWidth: '1024px'
              },
              {
                imgWidth: 200,
                mediaMinWidth: '768px'
              },
              {
                imgWidth: 200,
                mediaMinWidth: '438px'
              }
            ]}
            imgWidth={160}
          />}
        </TileLink>
      </div>
      <TileContent>
        <TileTitle>
          <a href={`#-${title}`} role='button' onClick={handleClick}>{title}</a>
        </TileTitle>
        {subtitle && <TileSubtitle>{subtitle}</TileSubtitle>}
        <AdditionalText>
          <PriceText>
            {priceText && <TilePrice>{priceText}</TilePrice>}
            {priceTextStrikethrough && <TileStrikethrough>{priceTextStrikethrough}</TileStrikethrough>}
          </PriceText>
          <TileTextSubtitle>{priceTextSubtitle}</TileTextSubtitle>
          {flagString && <FlagString>{flagString}</FlagString>}
          {!flagString && giftFlag &&
          <GiftFlag>
            <FreeGift icon={giftFlag.icon} description={giftFlag.description} />
          </GiftFlag>}
        </AdditionalText>
      </TileContent>
    </TileWrapper>
  );
};
