import { styled, css } from 'styled-components';
import { lighten } from 'polished';

export const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftColumn = styled.div`
  width: 220px;
  display: none;
  flex: 0 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    width: 240px;
    display: block;
    margin-right: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin-right: 60px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: block;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
`;

export const ProductHeading = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 15px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    text-align: left;
  }
`;

export const MobileImage = styled.div`
  display: block;
  width: 220px;
  margin: 0 auto 20px;
  padding-bottom: 15px;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const ProductDesc = styled.div`
  display: block;
  margin-bottom: 20px;
`;

export const ProductDescription = styled('div').withConfig({
  shouldForwardProp: props => !['expanded', 'needReadMoreButton'].includes(props)
})<{ expanded: boolean, needReadMoreButton: boolean }>`
  position: relative;
  max-height: 140px;
  ${({ theme }) => css`
    max-height: calc(clamp(${theme.lineHeights.lh3}, 3vw, ${theme.lineHeights.lh4}) * 5);
  `}
  overflow: hidden;

  ${({ expanded }) => expanded && css`
    max-height: unset;
  `}

  ${({ expanded, needReadMoreButton }) => !expanded && needReadMoreButton && css`
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.738) 19%, rgba(255, 255, 255, 0.541) 34%, rgba(255, 255, 255, 0.382) 47%, rgba(255, 255, 255, 0.278) 56.5%, rgba(255, 255, 255, 0.194) 65%, rgba(255, 255, 255, 0.126) 73%, rgba(255, 255, 255, 0.075) 80.2%, rgba(255, 255, 255, 0.042) 86.1%, rgba(255, 255, 255, 0.021) 91%, rgba(255, 255, 255, 0.008) 95.2%, rgba(255, 255, 255, 0.002) 98.2%, rgba(255, 255, 255, 0) 100%);
    }
  `}
`;

export const ReadMoreButton = styled.button`
  padding: 0;
  background: none;
  border: 0;
  font-size: ${({ theme }) => theme.fontSizes.fs2};
  text-decoration: underline;
  color: ${props => props.theme.colors.accent};
  cursor: pointer;
`;

export const ProductSubscriptions = styled.div`
  display: block;
  padding: 15px;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  margin-bottom: 30px;

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    padding: 30px;
    margin-bottom: 40px;
  }
`;

export const ErrorMessage = styled.div`
  padding: 20px 0;
  text-align: center;
  margin: 10px 0;
  background-color: ${props => lighten(0.9, props.theme.colors.red)};
  color: ${props => props.theme.colors.red};
`;

export const ActionWrapper = styled.div`
  margin-top: 20px;
`;

export const MobileBenefitsContainer = styled.div`
  display: block;
  margin-bottom: 30px;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const FeatureSubtitle = styled.div`
  text-transform: ${props => props.theme.fontDecor.fd4};
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  padding-bottom: 30px;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.mobile}) {
    ul {
      display: flex;
      flex-direction: row;
    }

    li {
      padding-right: 15px;
    }

    li::before {
      content: '•';
      color: ${props => props.theme.layout.primary};
      padding-right: 15px;
    }

    li:first-child::before {
      content: ' ';
      padding-right: 0;
    }
  }
`;

export const Promotion = styled.div`
  background-color: ${props => props.theme.colors.lightestGrey};
  padding: 10px;
  font-size: ${props => props.theme.fontSizes.fs1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  line-height: calc(${props => props.theme.fontSizes.fs0} + 1px);
  margin: 16px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    padding-right: 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    line-height: ${props => props.theme.lineHeights.lh0};
  }
`;

export const EndPrice = styled.p`
  margin-top: 20px;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};

  span {
    font-size: ${props => props.theme.fontSizes.fs4};
    font-weight: ${props => props.theme.fontWeights.fw2};
  }
`;

export const PricingString = styled.p`
  text-align: center;
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  color: ${props => props.theme.colors.darkGrey};
`;

export const OptionGroupWrapper = styled('div').withConfig({
  shouldForwardProp: props => !['hasOptions'].includes(props)
})<{ hasOptions: boolean }>`
  ${({ hasOptions }) => hasOptions && css`
    margin: 20px 0 30px;
  `}
`;

export const OneOptionTitleWrap = styled.div`
  margin-top: 20px;
`;
