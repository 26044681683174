import React from 'react';

import { Image } from '../Image';

import type { ProductImage as ProductImageProps } from './productImage.types';
export const MULTIPLIER_PORTRAIT = 1.3235;
export const MULTIPLIER_SQUARE = 1;
export const MULTIPLIER_LANDSCAPE = 0.75;
/**
 * Responsive product image which will render a `<picture>` with two images (1x and 2x) per media query. Mobile first strategy.
 *
 * @param {import('./productImage.types').ImageSrc} image Original image data.
 * @param {string} title Image's title to be added as alt tag to the image.
 * @param {number} imgWidth Default image width (mobile first).
 * @param {number} imgHeight Default image height (mobile first).
 * @param {{ imgWidth: number, mediaMinWidth: string }[]} variants Additional image sizes and min-width media query (big to small).
 * @param {import('./productImage.types').OrientationTypes} [orientation='portrait'] Aspect ratio of the image ('portrait' | 'landscape' | 'square')
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const ProductImage = ({ image, title, imgWidth, variants, orientation = 'portrait', parentIsLoading = false }:ProductImageProps): React.ReactElement => {
  const multiplier = {
    portrait: 1.3229167,
    square: 1,
    landscape: 0.75
  }[orientation];

  const getCompositeUrl = (baseUrl:string, path:string, width:number, multiplier:number):string => {
    const height = Math.round(width * multiplier);
    return `${baseUrl}/_fill_w${width}_h${height}${path}`;
  };

  const imgHeight = Math.round(imgWidth * multiplier);

  return (
    <Image
      title={title}
      src={image && getCompositeUrl(image.baseUrl, image.path, imgWidth, multiplier)}
      src2x={image && getCompositeUrl(image.baseUrl, image.path, imgWidth * 2, multiplier )}
      sources={image && variants.map(variant => ({ src: getCompositeUrl(image.baseUrl, image.path, variant.imgWidth, multiplier), src2x: getCompositeUrl(image.baseUrl, image.path, variant.imgWidth * 2, multiplier), minWidth: variant.mediaMinWidth }))}
      imageWidth={`${imgWidth}px`}
      imageHeight={`${imgHeight}px`}
      fit='cover'
      parentIsLoading={parentIsLoading}
    />
  );
};
