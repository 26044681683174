import createClient from 'openapi-fetch/dist/index.min';

import type { paths } from './api/openapi';
import { apiUrlByEnv } from '../config/index';

import { SettingsApi } from './api/apis/SettingsApi';
import { CampaignsApi } from './api/apis/CampaignsApi';
import { AssetsApi } from './api/apis/AssetsApi';
import { PromotionApi } from './api/apis/PromotionApi';

export const client = createClient<paths>({
  baseUrl: apiUrlByEnv,
  credentials: 'include'
});

export const apiClient = {
  settingsApi: SettingsApi(client),
  campaignsApi: CampaignsApi(client),
  assetsApi: AssetsApi(client),
  promotionApi: PromotionApi(client),
};

export type Client = typeof client;
