import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { NumberPlaceholderWrapper } from './numberPickerPlaceholders.styles';

export const NumberPlaceholder: React.FC<IContentLoaderProps> = (): React.ReactElement => (
  <NumberPlaceholderWrapper>
    <LoadingPlaceholder
      width={8}
      height={28}
    >
      <rect x="0" y="6" rx="0" ry="0" width="10" height="16" />
    </LoadingPlaceholder>
  </NumberPlaceholderWrapper>
);
