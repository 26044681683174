import { styled, css }from 'styled-components';

export const FlashWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['level'].includes(props)
})<{ level: string }>`
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${props => `${props.theme.breakpoints.mobileL}`}) {
    padding: 15px;
  }

  ${props => props.level === 'error' && css`
    border: 1px solid ${props => props.theme.layout.primary};
    border-left: 4px solid ${props => props.theme.layout.primary};
  `}

  ${props => props.level === 'warning' && css`
    border: 1px solid ${props => props.theme.colors.warning};
    border-left: 4px solid ${props => props.theme.colors.warning};
  `}

  ${props => props.level === 'success' && css`
    border: 1px solid ${props => props.theme.colors.success};
    border-left: 4px solid ${props => props.theme.colors.success};
  `}
`;

export const DescriptionWrapper = styled.div`
  line-height: ${props => props.theme.lineHeights.lh2};
`;

export const CheckIcon = styled('div').withConfig({
  shouldForwardProp: props =>
    !['level'].includes(props)
})< { level: string } >`
  svg {
    color: ${props => props.theme.colors.success};
    padding-right: 10px;
  }
`;

export const ErrorIcon = styled('div').withConfig({
  shouldForwardProp: props =>
    !['level'].includes(props)
})< { level: string } >`
  svg {
    color: ${props => props.theme.layout.primary};
    padding-right: 10px;
  }
`;
