import React from 'react';
import type { ProductListItem as ProductListItemProps } from './productListItem.types';
import ProductListItemSubtitlePlaceholder from './placeholders/productListItemSubtitlePlaceholder';
import ProductListItemPricePlaceholder from './placeholders/productListItemPricePlaceholder';
import ProductListItemDescriptionPlaceholder from './placeholders/productListItemDescriptionPlaceholder';

import { ProductImage, Title, Button, RichText } from '../../atoms';
import {
  ProductListItemWrapper,
  ImageWrapper,
  TextWrapper,
  Headings,
  HeadingFirst,
  HeadingLast,
  SubTitle,
  Duration,
  ProductPrice,
  ProductDescription,
  MobileCTA
} from './productListItem.styles';

/**
 * @param {string} id Provides an id for the item.
 * @param {string} title Provides a title for the item
 * @param {{ type:string, baseUrl:string, path:string }} image Provides an image object.
 * @param {string} subtitle Product id as key.
 * @param {string} postTitle Product course length.
 * @param {string} buttonText Button text to display.
 * @param {boolean} disabled Set the button state.
 * @param {string} description Provides a short description for the item.
 * @param {string} price Display price of item.
 * @param {string} sku Provides a sku.
 * @param {string} testid Provides a sku.
 * @param {(_id: string) => void} AddToBasketClick callback for onClick.
 * @param {string} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const ProductListItem = ({ id, image, title, subtitle, postTitle, description, onAddToBasketClick, buttonText = 'Add to basket', disabled, price, sku, testid = 'product-list-item', parentIsLoading = false }: ProductListItemProps): React.ReactElement => {
  return (
    <ProductListItemWrapper data-testid={testid} key={id}>
      <ImageWrapper>
        <ProductImage
          image={image}
          title={title}
          orientation='landscape'
          variants={[
            {
              imgWidth: 400,
              mediaMinWidth: '1024px'
            },
            {
              imgWidth: 340,
              mediaMinWidth: '768px'
            },
            {
              imgWidth: 384,
              mediaMinWidth: '414px'
            }
          ]}
          imgWidth={400}
          parentIsLoading={parentIsLoading}
        />
      </ImageWrapper>
      <TextWrapper>
        <SubTitle>{parentIsLoading ? <ProductListItemSubtitlePlaceholder /> : subtitle}</SubTitle>
        <Headings>
          <HeadingFirst>
            <Title text={title as string} tag='h3' parentIsLoading={parentIsLoading} />
            <Duration>{parentIsLoading ? <ProductListItemSubtitlePlaceholder /> : postTitle}</Duration>
          </HeadingFirst>
          <HeadingLast>
            <ProductPrice>{parentIsLoading ? <ProductListItemPricePlaceholder /> : price}</ProductPrice>
            <Button testid={`${testid}-trigger-dskt`} btnType='filled' size='large' disabled={parentIsLoading || disabled} onClick={() => onAddToBasketClick(sku as string)}>
              {buttonText}
            </Button>
          </HeadingLast>
        </Headings>
        <ProductDescription><RichText>{parentIsLoading ? <ProductListItemDescriptionPlaceholder /> : <div dangerouslySetInnerHTML={{ __html: description }}/>}</RichText></ProductDescription>
        <MobileCTA>
          <ProductPrice>{price}</ProductPrice>
          <Button testid={`${testid}-trigger-mbl`} btnType='filled' size='large' responsive disabled={disabled} onClick={() => onAddToBasketClick(sku as string)}>
            {buttonText}
          </Button>
        </MobileCTA>
      </TextWrapper>
    </ProductListItemWrapper>
  );
};
