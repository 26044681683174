import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';

const ExtraContentBodyPlaceholder: React.FC<IContentLoaderProps> = (props): React.ReactElement => (
  <LoadingPlaceholder
    style={{ marginBottom: '15px' }}
    width={800}
    height={234}
    {...props}
  >
    <rect x="0" y="5" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="31" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="57" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="83" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="109" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="135" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="161" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="187" rx="0" ry="0" width="800" height="16" />
    <rect x="0" y="213" rx="0" ry="0" width="170" height="16" />
  </LoadingPlaceholder>
);

export default ExtraContentBodyPlaceholder;
