import React from 'react';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';

import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import type { ConfirmationStatement as ConfirmationStatementProps } from './confirmationStatement.types';
import {
  CsWrapper,
  CsBody,
  CtaWrapper
} from './confirmationStatement.styles';

/**
 * @param {string} id Statement id.
 * @param {string} title Provides a title.
 * @param {React.ReactNode} body Provides html output.
 * @param {import('./confirmationStatement.types').Cta} [cta] Provides a call to action object.
 * @param {(id:string) => void} onClick Provides a callback to the onClick event.
*/

export const ConfirmationStatement = ({ id, testid = 'confirmationStatement', title, body, cta }:ConfirmationStatementProps):React.ReactElement => {

  return (
    <CsWrapper data-testid={testid} {...(id ? { id } : '')}>
      <Title text={`${title}`} tag='h2' />
      <CsBody dangerouslySetInnerHTML={{ __html: `${body}` }} />
      {cta ? <CtaWrapper>
        <Button
          {...cta}
          testid={testid}
          size='large'
          btnType='filled'
          onClick={e => cta.onClick && cta.onClick(e)}
          onKeyDown={e => executeOnKeyDown(e) && cta.onClick && cta.onClick(e)}
        >{cta?.label}</Button>
      </CtaWrapper> : ''}
    </CsWrapper>
  );
};
