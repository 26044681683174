import React from 'react';

import type { ProductOptionGroup as ProductOptionGroupProps } from './productOptionGroup.types';

import { ProductOptionTab } from '../../atoms';

import {
  GroupWrapper,
  ProductWrapper
} from './productOptionGroup.styles';

/**
 *
 * @param {import('./productOptionGroup.types').Filter[]} variants Product variants.
 * @param {string} promotionCopy Promotional copy.
 * @param {string} [optionSelected] Selected option.
 * @param {(id:string) => void} onSelect Callback on option select.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading
 *
 */

export const ProductOptionGroup = ({ variants, promotionCopy, optionSelected, onSelect, parentIsLoading = false }: ProductOptionGroupProps):React.ReactElement => {
  return (
    <GroupWrapper>
      {parentIsLoading ? (
        [1,2,3].map(val => (
          <ProductWrapper key={val} hasSignpost={false}>
            <ProductOptionTab parentIsLoading={parentIsLoading} />
          </ProductWrapper>
        ))
      ):(
        <>
          {variants && !!variants.length && variants.map(variant => (
            <ProductWrapper key={variant.id} hasSignpost={!!variant.promoted}>
              <ProductOptionTab
                title={variant.name || ''}
                price={variant.unitPrice}
                priceUnit='per issue'
                text='from'
                description={variant.description}
                signpost={variant.promoted ? promotionCopy : ''}
                selected={variant.id === optionSelected}
                onClick={onSelect ? () => onSelect(variant.id) : undefined}
              />
            </ProductWrapper>
          ))}
        </>
      )}

    </GroupWrapper>
  );
};
