import { styled, css } from 'styled-components';

export const Navigation = styled.div`
  box-sizing: border-box;
  display: inline-block;

  ul {
    display: flex;
    vertical-align: top;
  }


  nav {
    min-height: 39px;
    display: inline-block;
  }
`;

export const SubMenuWrapper = styled.div`
  position: absolute;
  top: 57px;
  margin: auto;
  display: none;
  box-sizing: border-box;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  width: 220px;
  box-shadow:
    0 32px 64px rgba(1, 1, 1, 0.07),
    0 16px 32px rgba(1, 1, 1, 0.07),
    0 8px 16px rgba(1, 1, 1, 0.07),
    0 4px 8px rgba(1, 1, 1, 0.07),
    0 2px 4px rgba(1, 1, 1, 0.07),
    0 1px 2px rgba(1, 1, 1, 0.07);
  z-index: ${props => props.theme.zIndexes.zi3};
`;

export const NavigationItem = styled.li`
  padding: 3px 0;
  margin-right: 20px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  svg {
    font-size: ${props => props.theme.fontSizes.fs3};
    transition: transform 0.5s ease-in-out;
    margin-left: 8px;
  }

  &:hover {
    ${SubMenuWrapper} {
      display: block;
    }

    svg {
      transform: rotate(180deg);
    }
  }
`;

export const NavItemLink = styled.a<{ selected: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  padding: 15px 20px;
  transition: 0.3s;
  line-height: ${props => props.theme.lineHeights.lh1};
  font-size: ${props => props.theme.fontSizes.fs1};
  text-transform: ${props => props.theme.fontDecor.fd4};

  span {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      transition: opacity 0.3s linear;
      left: 0;
      bottom: -5px;
      height: 2px;
      width: 100%;
      background-color: ${props => props.theme.layout.primary};
      opacity: 0;
      ${props => props.selected && css`
        opacity: 1;
      `}
    }
  }

  &:hover {
    color: ${props => props.theme.colors.black};

    span {
      &::after {
        opacity: 1;
      }
    }
  }
`;

export const SubMenu = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const SubMenuItem = styled('li').withConfig({
  shouldForwardProp: props =>
    !['initial'].includes(props)
})<{ initial?: boolean }>`
  a {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.black};
    text-decoration: none;
    transition: 0.3s;
    line-height: ${props => props.theme.lineHeights.lh1};
    font-size: ${props => props.theme.fontSizes.fs1};
    padding: 7px 25px;
    text-transform: none;
    ${ props => props.initial && css`
      font-weight: ${props.theme.fontWeights.fw2};
    ` }

    &:hover {
      background-color: ${props => props.theme.colors.lightestGrey};
    }
  }
`;
