import React, { useState, useEffect } from 'react';

import type { IncludedContent as IncludedContentProps } from './includedContent.types';

import { Image, RichText, Button, Icon } from '../../atoms';
import IncludedContentTitlePlaceholder from './placeholders/includedContentTitlePlaceholder';
import IncludedContentContentPlaceholder from './placeholders/includedContentContentPlaceholder';
import IncludedContentContentMobilePlaceholder from './placeholders/includedContentContentMobilePlaceholder';

import {
  ContentWrapper,
  ContentItem,
  ImageWrapper,
  RichTextWrapper,
  ContentTitle,
  CtaWrapper
} from './includedContent.styles';

/**
 *
 * @param {() => void} [onClick] Callback for content action.
 * @param {string} [label] Label for action CTA.
 * @param {import('./includedContent.types').Content} content Content to be shown.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading
 *
 */

export const IncludedContent = ({ label, onClick, content, testid = 'included_content', parentIsLoading = false }:IncludedContentProps):React.ReactElement => {
  const [queryMatches, setQueryMatches] = useState<{ matches: boolean }>({ matches: true });

  useEffect(() => {
    if (typeof window !== undefined) {
      const onMediaQueryChange = (e: MediaQueryListEvent) => setQueryMatches({ matches: e.matches });
      const matchMedia = window.matchMedia('(min-width: 768px)');
      matchMedia.addEventListener('change', onMediaQueryChange);
      return () => matchMedia.removeEventListener('change', onMediaQueryChange);
    }
  }, []);

  if (parentIsLoading) {
    return (
      <div>
        <ContentWrapper data-testid={testid}>
          {[1,2,3].map(item => {
            return (
              <ContentItem key={item} data-testid={`${testid}-item`}>
                <ImageWrapper>
                  <Image parentIsLoading title='Loading' imageHeight='191px' imageWidth='340px' fit='cover' positionX='center' positionY='center' />
                  <ContentTitle hasPlaceholder><IncludedContentTitlePlaceholder /></ContentTitle>
                </ImageWrapper>
                <RichTextWrapper>
                  { queryMatches.matches ? <IncludedContentContentPlaceholder /> : <IncludedContentContentMobilePlaceholder /> }
                </RichTextWrapper>
              </ContentItem>
            );
          })}
        </ContentWrapper>
        <CtaWrapper />
      </div>
    );
  }

  return (
    <div>
      <ContentWrapper data-testid={testid}>
        {content.map(item => {
          return (
            <ContentItem key={item.title} data-testid={`${testid}-item`}>
              <ImageWrapper>
                <Image src={`${item.image?.baseUrl}${item.image?.path}`} title={item.title || ''} imageHeight='191px' imageWidth='340px' fit='cover' positionX='center' positionY='center' />
                <ContentTitle>{item.title}</ContentTitle>
              </ImageWrapper>
              <RichTextWrapper>
                <RichText>
                  <div dangerouslySetInnerHTML={{ __html: item.body as string }} />
                </RichText>
              </RichTextWrapper>
            </ContentItem>
          );
        })}
      </ContentWrapper>
      <CtaWrapper>
        {label && <Button btnType='text' testid='see_inside' size='large' onClick={onClick}><span><Icon glyph='eye'/></span>{label}</Button>}
      </CtaWrapper>
    </div>
  );
};
