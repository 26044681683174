import { styled, css } from 'styled-components';

export const TextBoxWrapper = styled.div`
  position: relative;
  display: block;
`;

export const Input = styled('input').withConfig({
  shouldForwardProp: props =>
    !['state'].includes(props)
})<{ state: string }>`
  width: 100%;
  height: 36px;
  border: 1px solid ${props => props.theme.colors.beige};
  background-color: ${props => `${props.theme.colors.white}`};
  border-radius: 4px;
  display: block;
  box-sizing: border-box;
  padding: 10px;
  outline: none;
  line-height: ${({ theme }) => theme.lineHeights.lh1};
  font-size: ${({ theme }) => theme.fontSizes.fs1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  color: ${props => props.theme.colors.black};
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletS}) {
    height: 44px;
    font-size: ${({ theme }) => theme.fontSizes.fs2};
    line-height: ${({ theme }) => theme.lineHeights.lh2};
  }

  ${props => props.state === 'error' && css`
    border: 2px solid ${props.theme.colors.error};
  `}

  ${props => props.state === 'verified' && css`
    border: 2px solid ${props.theme.colors.success};
  `}

  &::placeholder {
    color: ${props => props.theme.colors.darkGrey};
    opacity: 1;
  }

  &:hover {
    background-color: ${props => props.theme.colors.lightBeige};
  }

  &:focus {
    border: 2px solid ${props => props.theme.colors.focus};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.lightestGrey};
    border-color: ${props => props.theme.colors.grey};
  }
`;

export const IconWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['state'].includes(props)
})<{ state: string }>`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  line-height: ${props => props.theme.lineHeights.lh2};

  svg {
    ${props => props.state === 'error' && css`
      color: ${props.theme.colors.error};
    `}

    ${props => props.state === 'verified' && css`
      color: ${props.theme.colors.success};
    `}
  }
`;
