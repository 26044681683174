import { styled } from 'styled-components';

export const TileWrapper = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  box-sizing: border-box;

  @media (min-width: ${props => `${props.theme.breakpoints.mobile}`}) {
    max-width: 200px;
    margin-bottom: 30px;
  }

  @media (min-width: ${props => `${props.theme.breakpoints.laptop}`}) {
    max-width: 250px;
  }
`;

export const TileContent = styled.div`
  min-height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const TileLink = styled.a`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  max-height: 156px;
  overflow: hidden;
  width: 100%;

  @media (min-width: ${props => `${props.theme.breakpoints.mobile}`}) {
    max-height: 230px;
  }
`;

export const TileTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh2};
  margin-top: 8px;
  height: 42px;
  display: flex;
  align-items: center;

  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
`;

export const TilePrice = styled.div`
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  color: ${props => props.theme.layout.primary};
`;

export const AdditionalText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 64px;
`;

export const TileSubtitle = styled.div`
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  color: ${props => props.theme.colors.darkGrey};
`;

export const TileStrikethrough = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  text-decoration: line-through;
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  padding-left: 5px;
`;

export const TileTextSubtitle = styled.div`
  color: ${props => props.theme.colors.darkGrey};
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
`;

export const FlagString = styled.div`
  background-color: ${props => props.theme.layout.primary};
  color: ${props => props.theme.colors.white};
  padding: 2px 4px;
  text-transform: uppercase;
  width: fit-content;
  margin-top: 10px;
  font-weight: ${props => props.theme.fontWeights.fw1};
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
`;

export const GiftFlag = styled.div`
  div {
    padding: 5px;
    margin: 0;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PriceText = styled.div`
  display: flex;
`;
