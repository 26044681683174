import React from 'react';

import type { DesktopFilterGroup as DesktopFilterGroupProps } from './desktopFilterGroup.types';

import { DropDownMenu } from '../../atoms/DropDownMenu';
import { Checkbox } from '../../atoms/Checkbox';

import {
  FilterWrapper,
  FilterContainer
} from './desktopFilterGroup.styles';

/**
 *
 * @param {string} groupTitle Label of the group CTA.
 * @param {import('./desktopFilterGroup.types').Filter[]} filters Filters included in this group.
 * @param {(selected: string[]) => void} onFilterSelection Callback trigger on each selection.
 * @param {string[]} [selectedFilters=[]] Array of selected filters.
 *
 */

export const DesktopFilterGroup = ({ groupTitleLabel, groupTitleID, filters, onFilterSelection, id, testid = 'desk-filters', selectedFilters = [] }:DesktopFilterGroupProps):React.ReactElement => {

  return (
    <DropDownMenu title={groupTitleLabel} testid={testid} {...(id ? { id } : {})}>
      <FilterContainer>
        {filters.map((filter, i) => {
          const qtyLabel = filter?.qty ? `(${filter.qty})` : '';
          return (
            <FilterWrapper key={filter.id} isLastItem={i === filters.length - 1}>
              <Checkbox
                label={`${filter.label} ${qtyLabel}`}
                id={filter.id}
                testid='filter-checkbox'
                onChange={selected => onFilterSelection(groupTitleID || groupTitleLabel.toLowerCase(), filter.id, selected)}
                checked={selectedFilters.includes(filter.id)}
                disabled={filter?.qty === 0}
              />
            </FilterWrapper>
          );
        })}
      </FilterContainer>
    </DropDownMenu>
  );
};
