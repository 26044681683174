import { Client } from '../../apiConfig';

export const PromotionApi = (client: Client) => ({
  getPromotions: ({ token, promoCodeQuery, abortSignal }: { token: string, promoCodeQuery: string, abortSignal: AbortSignal }) => {
    const moreParams: any = {};
    if (abortSignal) {
      moreParams.signal = abortSignal;
    }
    return client.GET('/api/ace/bluebird/promotion', {
      params: {
        query: {
          code: promoCodeQuery
        }
      },
      headers: {
        'Authorization': `Bearer ${token}`
      },
      ...moreParams
    });
  }
});
