import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { TitlePlaceholderWrapper } from './titlePlaceholder.styles';

interface TitlePlaceholderProps extends IContentLoaderProps {
  titleTag?: string
}

const TitlePlaceholder: React.FC<TitlePlaceholderProps> = ({ titleTag, ...rest }): React.ReactElement => (
  <TitlePlaceholderWrapper titleTag={titleTag}>
    <LoadingPlaceholder
      width={120}
      height={28}
      {...rest}
    >
      <rect x="0" y="2" rx="0" ry="0" width="120" height="24" />
    </LoadingPlaceholder>
  </TitlePlaceholderWrapper>
);

export default TitlePlaceholder;
