import {
  faEye,
  faUser,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faShield,
  faTruck,
  faTruckFast,
  faPiggyBank,
  faCircleExclamation,
  faBell,
  faBasketShopping,
  faShieldHalved,
  faPlus,
  faMinus,
  faBars,
  faXmark,
  faSpinner,
  faCartShopping,
  faCircleCheck,
  faCheck,
  faArrowPointer,
  faArrowRight,
  faCoins,
  faTurnUp,
  faCaretDown,
  faPencil,
  faLock,
  faTag,
  faPrint,
  faSliders,
  faBookOpen,
  faGift,
  faCrown,
  faInfinity,
  faShieldHeart,
  faTrashCan,
  faGifts,
  faStar,
  faShirt,
  faBook,
  faRibbon,
  faBagShopping,
  faFutbol,
  faNewspaper,
  faHeart,
  faTree,
  faMugSaucer,
  faAward,
  faWineBottle,
  faUserCheck,
  faGolfBallTee,
  faGuitar,
  faBicycle,
  faCamera,
  faGamepad,
  faMessage,
  faEnvelope,
  faPhone,
  faInfo
} from '@fortawesome/free-solid-svg-icons';

import { faPaypal } from '@fortawesome/free-brands-svg-icons';

import { faCalendar, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

export interface IconComponentProps {
  title?: string,
  width?: string,
  height?: string,
  fill?: string
}

export type IconsType = 'eye' | 'paypal_logo' | 'account_solid' | 'shopping_cart' | 'arrow_pointer' | 'arrow_down' | 'arrow_up' | 'arrow_left' | 'arrow_right' | 'shield_check' | 'truck' | 'truck_fast' | 'piggy_bank' | 'alarm' | 'shopping_basket' | 'secure_payment' | 'add' | 'subtract' | 'menu' | 'close' | 'loader' | 'check_line' | 'check_circle' | 'checkbox_circle_fill' | 'arrow_right_line' | 'money_pound' | 'arrow_right_up' | 'arrow_down_fill' | 'pencil_fill' | 'lock_solid' | 'tag_solid' | 'print' | 'filters' | 'error_warning' | 'book_open' | 'calendar' | 'gift_card' | 'crown' | 'infinity' | 'shield_heart' | 'trash_can' | 'gifts' | 'star' | 'shirt' | 'book' | 'ribbon' | 'bag_shopping' | 'Futbol' | 'newspaper' | 'heart' | 'tree' | 'mug_saucer' | 'award' | 'wine_bottle' | 'user_check' | 'golf_ball_tee' | 'guitar' | 'bicyle' | 'camera' | 'gamepad' | 'message' | 'envelope' | 'phone' | 'info';

const Icons:{ [key: string]: any} = {
  EYE: faEye,
  ACCOUNT_SOLID: faUser,
  ARROW_POINTER: faArrowPointer,
  ARROW_DOWN: faAngleDown,
  ARROW_UP: faAngleUp,
  ARROW_LEFT: faAngleLeft,
  ARROW_RIGHT: faAngleRight,
  SHIELD_CHECK: faShield,
  TRUCK: faTruck,
  TRUCK_FAST: faTruckFast,
  PIGGY_BANK: faPiggyBank,
  ERROR_WARNING: faCircleExclamation,
  ALARM: faBell,
  SHOPPING_BASKET: faBasketShopping,
  SECURE_PAYMENT: faShieldHalved,
  ADD: faPlus,
  SUBTRACT: faMinus,
  MENU: faBars,
  CLOSE: faXmark,
  LOADER: faSpinner,
  SHOPPING_CART: faCartShopping,
  CHECK_CIRCLE: faCheckCircle,
  CHECKBOX_CIRCLE_FILL: faCircleCheck,
  CHECK_LINE: faCheck,
  ARROW_RIGHT_LINE: faArrowRight,
  MONEY_POUND: faCoins,
  ARROW_RIGHT_UP: faTurnUp,
  ARROW_DOWN_FILL: faCaretDown,
  PENCIL_FILL: faPencil,
  LOCK_SOLID: faLock,
  TAG_SOLID: faTag,
  PRINT: faPrint,
  FILTERS: faSliders,
  PAYPAL_LOGO: faPaypal,
  BOOK_OPEN: faBookOpen,
  CALENDAR: faCalendar,
  GIFT_CARD: faGift,
  CROWN: faCrown,
  INFINITY: faInfinity,
  SHIELD_HEART: faShieldHeart,
  TRASH_CAN: faTrashCan,
  GIFTS: faGifts,
  STAR: faStar,
  SHIRT: faShirt,
  BOOK: faBook,
  RIBBON: faRibbon,
  BAG_SHOPPING: faBagShopping,
  FUTBOL: faFutbol,
  NEWSPAPER: faNewspaper,
  HEART: faHeart,
  TREE: faTree,
  MUG_SAUCER: faMugSaucer,
  AWARD: faAward,
  WINE_BOTTLE: faWineBottle,
  USER_CHECK: faUserCheck,
  GOLF_BALL_TEE: faGolfBallTee,
  GUITAR: faGuitar,
  BICYCLE: faBicycle,
  CAMERA: faCamera,
  GAMEPAD: faGamepad,
  MESSAGE: faMessage,
  ENVELOPE: faEnvelope,
  PHONE: faPhone,
  INFO: faInfo
};

export default Icons;
