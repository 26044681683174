import { styled } from 'styled-components';

// Wants Libra Franklin font family (from design)

export const BannerWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['backgroundColour', 'textColour'].includes(props)
})<{backgroundColour: string, textColour: string}>`
  text-align: center;
  ${props => props.theme.headings.smallBold};
  background-color: ${props => props.backgroundColour};
  color: ${props => props.textColour};
  padding: 6px 0;
  font-size: 14px;

  button {
    cursor: pointer;
    background: none;
    color: inherit;
    border: 0;
    font-size: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;
