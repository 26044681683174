import React from 'react';
import type { ProductOptionListItem as ProductOptionListItemProps } from './productOptionListItem.types';
import ProductOptionListItemTitlePlaceholder from './placeholders/productOptionListItemTitlePlaceholder';
import ProductOptionListItemSavingPlaceholder from './placeholders/productOptionListItemSavingPlaceholder';
import ProductOptionListItemDescriptionPlaceholder from './placeholders/productOptionListItemDescriptionPlaceholder';
import ProductOptionListItemPricingPlaceholder from './placeholders/productOptionListItemPricingPlaceholder';

import { executeOnKeyDown } from '../../../utils/keyboardEvents';

import {
  ProductOptionListItemWrapper,
  DesktopWrapper,
  OptionTitleWrapper,
  OptionListItemTitle,
  OptionDescription,
  Pricing,
  SubscriptionWrapper,
  SavingPromotion,
  OriginalPrice,
  SubPriceWrapper,
  SavingPromotionWrapper,
  FollowOnPricing
} from './productOptionListItem.styles';

/**
 *
 * @param {string} [title = ''] Item's title.
 * @param {string} [price = ''] Item's price.
 * @param {string} [followOnPrice] Item's follow on price.
 * @param {string} [pricingTemplate] Item's follow on pricing template.
 * @param {string} [saving] Total savings.
 * @param {() => void} [onClick] On click event callback.
 * @param {boolean} [selected=false] Item selected.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading.
 * @param {string} [originalPrice] Provides a strike-through price.
 * @param {boolean} [hasFollowOnPricing] Shows follow on price.
 */

export const ProductOptionListItem = ({ title = '', description, price = '', followOnPrice, pricingTemplate, saving, onClick, selected=false, testid = 'product-list-item', parentIsLoading = false, hasFollowOnPricing, originalPrice }: ProductOptionListItemProps): React.ReactElement => {

  const addPricesToTemplate = (): string => {
    const priceTemplate = '{{unitPrice}}';
    const followTemplate = '{{followOnPrice}}';

    if(!hasFollowOnPricing) {
      return pricingTemplate ? pricingTemplate.replace(priceTemplate, `<span>${price}</span>` || '') : '';
    }
    return pricingTemplate ? pricingTemplate.replace(priceTemplate, `<b>${price}</b>` || '').replace(followTemplate, `<b>${followOnPrice}</b>` || '') : '';
  };

  if (parentIsLoading) {
    return (
      <ProductOptionListItemWrapper selected={selected} role='button' tabIndex={0} data-testid={testid} onClick={() => onClick && onClick()} onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => executeOnKeyDown(e) && onClick && onClick()}>
        <DesktopWrapper>
          <OptionTitleWrapper>
            <ProductOptionListItemTitlePlaceholder />
            <ProductOptionListItemDescriptionPlaceholder />
          </OptionTitleWrapper>
          <SubscriptionWrapper>
            <ProductOptionListItemPricingPlaceholder isMobile={false} />
            <ProductOptionListItemSavingPlaceholder style={{ marginLeft: '10px' }} />
          </SubscriptionWrapper>
        </DesktopWrapper>
        <ProductOptionListItemPricingPlaceholder isMobile={true} />
      </ProductOptionListItemWrapper>
    );
  }

  return (
    <ProductOptionListItemWrapper selected={selected} role='button' tabIndex={0} data-testid={testid} onClick={() => onClick && onClick()} onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => executeOnKeyDown(e) && onClick && onClick()}>
      <DesktopWrapper>
        <OptionTitleWrapper>
          <OptionListItemTitle>{title}</OptionListItemTitle>
          {description && <OptionDescription>{description}</OptionDescription>}
        </OptionTitleWrapper>
        <SubscriptionWrapper>
          {(!hasFollowOnPricing || (originalPrice && originalPrice <= price)) ?
            <SubPriceWrapper>
              {originalPrice && <OriginalPrice>{originalPrice}</OriginalPrice>}
              <Pricing data-testid={`${testid}-punit`} dangerouslySetInnerHTML={{ __html: addPricesToTemplate() }} />
            </SubPriceWrapper> :
            <FollowOnPricing followOnPrice>
              <Pricing data-testid={`${testid}-followonprice`} dangerouslySetInnerHTML={{ __html: addPricesToTemplate() }}/>
            </FollowOnPricing>
          }
          {saving && !hasFollowOnPricing &&
            <SavingPromotionWrapper>
              <SavingPromotion selected={selected} data-testid={`${testid}-saving`}>{saving}</SavingPromotion>
            </SavingPromotionWrapper>
          }
        </SubscriptionWrapper>
      </DesktopWrapper>
    </ProductOptionListItemWrapper>
  );
};
