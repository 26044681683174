import React from 'react';

import { Button } from '../Button';
import { Icon } from '../Icon';

import { FilterDisplay as FilterDisplayProps } from './filterDisplay.types';

import {
  FilterDisplayWrapper,
  FilterButton
} from './filterDisplay.styles';

/**
 *
 * @param {(_id:string) => void} [handleRemoveSingleFilter] handleRemoveSingleFilter Callback to handle single filter.
 * @param {() => void} [handleRemoveAllFilter] handleRemoveAllFilter Callback to handle all filters.
 * @param {import('./filterDisplay.types').FilterDisplayFilter[]} filters Filter properties
 *
 */

export const FilterDisplay = ({ filters, testid = 'filter-display', handleRemoveAllFilter, handleRemoveSingleFilter }: FilterDisplayProps):React.ReactElement => {

  return (
    <FilterDisplayWrapper data-testid={testid}>
      <FilterButton>
        {filters.map(filter => {
          const qty = filter.qty ? `(${filter.qty})` : '';
          return (
            <Button key={filter.id} onClick={() => handleRemoveSingleFilter?.(filter.groupTitleID, filter.id)} btnType='outlined' size='large' testid={testid}>{`${filter.label} ${qty}`}<Icon glyph='close' /></Button>
          );
        })}
        <Button onClick={handleRemoveAllFilter} btnType='text' size='large' testid={testid}>Clear All</Button>
      </FilterButton>
    </FilterDisplayWrapper>
  );
};
