import { styled } from 'styled-components';

export const TabsWrapper = styled.div`
  width: calc(100% - 30px);
  padding: 20px 15px;
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
    width: calc(100% - 100px);
    padding: 30px 50px 50px;
    border: 0;
    background-color: inherit;
  }
`;

export const TabsTitle = styled.h2`
  text-align: center;
  text-transform: ${props => props.theme.fontDecor.fd4};
  font-weight: ${props => props.theme.fontWeights.fw2};
  font-size: ${props => props.theme.fontSizes.fs5};
  line-height: ${props => props.theme.lineHeights.lh5};
  padding-bottom: 20px;

  @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
    ${props => props.theme.headings.xxLarge}
    padding-bottom: 30px;
  }
`;

export const TabsList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const TabWrapper = styled.div`
  padding: 0 0 10px;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.tabletS}) {
    padding: 0 20px 20px 0;
    width: auto;

    &:last-child {
      padding-right: 0;
    }
  }
`;
