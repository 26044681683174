import { css, styled } from 'styled-components';

export const FieldWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['hasSelected'].includes(props)
})<{hasSelected: boolean}>`
  position: relative;

  ${props => props.hasSelected && css`
    input {
      padding-top: 30px;
      height: 64px;
    }
  `}
`;

export const TagsWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
`;

export const TagWrapper = styled.div`
  margin-right: 10px;
`;

export const ResultsWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['visible', 'hasSelected'].includes(props)
})<{visible: boolean, hasSelected: boolean}>`
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: ${props => props.hasSelected ? '62px' : '42px'};
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  visibility: ${props => props.visible ? 'visisble' : 'hidden'};
  border-radius: 6px;
  font-size: ${props => props.theme.fontSizes.fs1};
  color: ${props => props.theme.colors.darkGrey};
  z-index: ${props => props.theme.zIndexes.zi3};
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);

  @media (min-width: 500px) {
    min-width: 500px;
  }
`;

export const ResultsList = styled.ul`
  overflow-y: auto;
  max-height: 240px;
`;

export const ResultButton = styled.a`
  display: block;
  padding: 10px 15px;
  box-sizing: border-box;
  background: none;
  border: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  line-height: ${props => props.theme.lineHeights.lh0};

  &:hover {
    color: ${props => props.theme.colors.black};
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  span {
    color: ${props => props.theme.colors.gray};
    font-size: 12px;
    display: block;
  }

  b {
    color: ${props => props.theme.colors.black};
  }
`;

export const SuggestionsHeading = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  font-size: ${({ theme }) => theme.fontSizes.fs1};
  color: ${({ theme }) => theme.colors.black};
`;
