import React, { useState, useEffect } from 'react';

import { ProductOptionList, ProductOptionGroup, CheckoutActions } from '../../molecules';
import { BenefitCard, ProductImage, Title, RichText, FreeGift } from '../../atoms';

import { executeOnKeyDown } from '../../../utils';

import type { ProductDisplay as ProductDisplayProps } from './productDisplay.types';
import type { ProductOptionListItem as Variant } from '../../molecules/ProductOptionListItem/productOptionListItem.types';
import type { Filter } from '../../molecules/ProductOptionGroup/productOptionGroup.types';
import type { IconsType } from '../../../icons/index';
import ProductDescriptionPlaceholder from './placeholders/productDescriptionPlaceholder';
import ProductSubtitlePlaceholder from './placeholders/productSubtitlePlaceholder';
import ExtrasPlaceholder from './placeholders/extrasPlaceholder';
import PricingStringPlaceholder from './placeholders/pricingStringPlaceholder';
import EndPricePlaceholder from './placeholders/endPricePlaceholder';

import {
  ProductWrapper,
  LeftColumn,
  ImageWrapper,
  MainContent,
  ProductHeading,
  MobileImage,
  ProductDesc,
  ProductSubscriptions,
  ErrorMessage,
  ActionWrapper,
  ActionContentWrapper,
  ActionLoading,
  MobileBenefitsContainer,
  FeatureSubtitle,
  EndPrice,
  PricingString
} from './productDisplay.styles';

const benefits = [
  {
    icon: 'arrow_pointer' as IconsType,
    label: 'Cancel any time'
  },
  {
    icon: 'money_pound' as IconsType,
    label: 'Best price guarantee'
  },
  {
    icon: 'truck' as IconsType,
    label: 'No hidden costs'
  },
  {
    icon: 'shield_check' as IconsType,
    label: 'You\'re in control'
  }
];

/**
 *
 * @param {import('./productDisplay.types').Product} product Product to be displayed.
 * @param {string} [pickerTitle] Title for the variant picker.
 * @param {import('../../molecules/ProductOptionGroup/productOptionGroup.types')[]} [filters] Product variants filters.
 * @param {string} [promotionalCopy] Copy to be added over promoted filter.
 * @param {(id:string, payment:string) => void} onAddToBasket Callback for the add to basket button.
 * @param {boolean} addError If adding item to basket caused an error.
 * @param {boolean} addLoading When adding to basket is processing.
 * @param {string} [notification] Error type.
 * @param {import('../../molecules/CheckoutActions/checkoutActions.types').Option[]} checkoutOptions Available checkout actions.
 * @param {boolean} [showBenefitCard=false] Indicate if to show the BenefitCard.
 * @param {string} [selectionTitle] Title for the ProductVariantDisplay.
 * @param {{freeGiftIcon: string, freeGiftDescription: string}} promotion Any promotion attached to the item.
 * @param {React.ReactNode} [extras] Additional component/functionality.
 * @param {boolean} [parentIsLoading = false] Indicates parent is still loading
 *
 */

export const ProductDisplay = ({ product, pickerTitle, filters, onAddToBasket, addError, addLoading, notification, onSelect, checkoutOptions, showBenefitCard = false, promotion, testid = 'productdisplay', promotionalCopy, selectionTitle, extras, parentIsLoading = false }:ProductDisplayProps):React.ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(product?.defaultVariant);
  const [variantsToShow, setVariantsToShow] = useState<Variant[] | []>([]);

  useEffect(() => {
    if (product) {
      setSelectedVariant(product.defaultVariant);
      if (filters) {
        setFilters(product.defaultVariant, filters);
      } else {
        setVariantsToShow(product.variants);
      }
    }
  }, [product]);

  const setFilters = (variant: Variant, currentFilters: Filter[]):void => {
    const [selFilter] = currentFilters.filter(flt => variant.options?.includes(flt.id));
    if (selFilter) {
      setVariantsToShow(filterVariants(product?.variants as Variant[], selFilter.id));
      setSelectedFilter(selFilter.id);
    }
  };

  const filterVariants = ( allVariants:Variant[], filterId:string):Variant[] => {
    return allVariants.filter(item => item.options?.includes(filterId));
  };

  const onFilter = (filterId:string):void => {
    setSelectedFilter(filterId);
    const filteredVariants = filterVariants(product?.variants as Variant[], filterId);
    if(filteredVariants) {
      setVariantsToShow(filteredVariants);
      setSelectedVariant(filteredVariants[0]);
    }
  };

  const handleSelectedVariant = (variantCode:string): void => {
    const selected = product?.variants.filter(variant => variant.sku === variantCode)[0];
    setSelectedVariant(selected);
    if(onSelect) onSelect();
  };

  const hasSelection = ():boolean => (!!filters && filters.length > 0) || (!!product?.variants && product.variants.length > 1);

  return (
    <ProductWrapper data-testid={`${testid}-wrapper`} >
      <LeftColumn>
        <ImageWrapper>
          <ProductImage
            title={product?.title || ''}
            imgWidth={240}
            orientation='portrait'
            image={product?.images ? product.images[0] : undefined}
            variants={[
              {
                imgWidth: 240,
                mediaMinWidth: '1024px'
              },
              {
                imgWidth: 240,
                mediaMinWidth: '768px'
              },
              {
                imgWidth: 220,
                mediaMinWidth: '414px'
              }
            ]}
            parentIsLoading={parentIsLoading}
          />
        </ImageWrapper>
        {parentIsLoading ? <BenefitCard benefits={benefits} parentIsLoading={parentIsLoading} /> : showBenefitCard && <BenefitCard benefits={benefits} />}
      </LeftColumn>
      <MainContent>
        <ProductHeading>
          <Title tag='h1' text={product?.title || ''} parentIsLoading={parentIsLoading} />
        </ProductHeading>
        {parentIsLoading ? (
          <ProductSubtitlePlaceholder />
        ) : (
          <>
            {product?.subtitle &&
              <FeatureSubtitle>
                <div data-testid={`${testid}-subtitle`} dangerouslySetInnerHTML={{ __html: product.subtitle }}/>
              </FeatureSubtitle>}
          </>
        )}
        <MobileImage>
          <ProductImage
            title={product?.title || ''}
            imgWidth={220}
            orientation='portrait'
            image={product?.images ? product.images[0] : undefined}
            variants={[
              {
                imgWidth: 240,
                mediaMinWidth: '1024px'
              },
              {
                imgWidth: 240,
                mediaMinWidth: '768px'
              },
              {
                imgWidth: 220,
                mediaMinWidth: '414px'
              }
            ]}
            parentIsLoading={parentIsLoading}
          />
        </MobileImage>
        <ProductDesc>
          {parentIsLoading ? (
            <div style={{ overflow: 'hidden' }}>
              <ProductDescriptionPlaceholder />
            </div>
          ) : (
            <>
              {expanded
                ? (<>
                  <RichText><div dangerouslySetInnerHTML={{ __html: product?.description || '' }}/></RichText>
                  <p><a role="button" data-testid="show-less" tabIndex={0} onClick={() => setExpanded(false)} onKeyDown={e => executeOnKeyDown(e) && setExpanded(false)}>Read fewer details</a></p>
                </>)
                : (<>
                  <RichText><div dangerouslySetInnerHTML={{ __html: product?.shortDescription || '' }}/></RichText>
                  {product?.description && <p><a role="button" data-testid="show-more" tabIndex={0} onClick={() => setExpanded(true)} onKeyDown={e => executeOnKeyDown(e) && setExpanded(true)}>Read more details</a></p>}
                </>)
              }
            </>
          )}

        </ProductDesc>
        <ProductSubscriptions>
          {hasSelection() && <Title text={pickerTitle || ''} tag='h2' parentIsLoading={parentIsLoading} />}
          <div style={{ padding: '20px 0 30px' }}>
            <ProductOptionGroup
              variants={filters && filters.length > 1 ? filters : undefined}
              optionSelected={selectedFilter}
              onSelect={onFilter}
              promotionCopy={promotionalCopy || ''}
              parentIsLoading={parentIsLoading}
            />
          </div>
          {parentIsLoading ? <ExtrasPlaceholder /> : extras && <div>{extras}</div>}
          {parentIsLoading ? (
            <ProductOptionList parentIsLoading={parentIsLoading} />
          ) : (
            <>
              {product?.variants && product?.variants.length > 1
                ? variantsToShow.length > 1 && <ProductOptionList
                  variants={variantsToShow}
                  onSelect={handleSelectedVariant}
                  selected={selectedVariant?.sku}
                  title={selectionTitle}
                />
                : <Title text={`${product?.defaultVariant?.title} for ${product?.defaultVariant?.price}`} tag='h2' parentIsLoading={parentIsLoading} />
              }
            </>
          )}
          {addError && notification === 'add' && <ErrorMessage>Sorry, there was an error adding the item please try again later.</ErrorMessage>}
          {parentIsLoading ? (
            <FreeGift parentIsLoading={parentIsLoading} />
          ) : (
            <>
              {promotion && <FreeGift testid={`${testid}-freegift`} icon={promotion.freeGiftIcon} description={promotion.freeGiftDescription} />}
            </>
          )}
          {parentIsLoading ? (
            <div style={{ textAlign: 'center' }}>
              <EndPricePlaceholder />
              <PricingStringPlaceholder />
            </div>
          ) : product?.variants && product.variants.length > 1 &&
            <>
              <EndPrice>Total price today: <span>{selectedVariant?.price}</span></EndPrice>
              <PricingString>{selectedVariant?.pricingString}</PricingString>
            </>
          }
          <ActionWrapper>
            <ActionContentWrapper>
              <ActionLoading addLoading={addLoading} />
              <CheckoutActions testid='pdp-action' options={checkoutOptions} onOptionSelect={selected => onAddToBasket(selectedVariant?.sku as string, selected)} />
            </ActionContentWrapper>
          </ActionWrapper>
        </ProductSubscriptions>
        {parentIsLoading ? <MobileBenefitsContainer><BenefitCard benefits={benefits} parentIsLoading={parentIsLoading} /></MobileBenefitsContainer> : showBenefitCard && <MobileBenefitsContainer><BenefitCard benefits={benefits} /></MobileBenefitsContainer>}

      </MainContent>
    </ProductWrapper>
  );
};
