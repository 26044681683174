import React from 'react';
import { IContentLoaderProps } from 'react-content-loader';
import { LoadingPlaceholder } from '../../../atoms/LoadingPlaceholder';
import { ProductOptionListItemPricingPlaceholderWrapper } from './productOptionListItemPlaceholder.styles';

interface ProductOptionListItemPricingPlaceholderProps extends IContentLoaderProps {
  isMobile?: boolean
}

const ProductOptionListItemPricingPlaceholder: React.FC<ProductOptionListItemPricingPlaceholderProps> = ({ isMobile, style, ...rest }): React.ReactElement => (
  <ProductOptionListItemPricingPlaceholderWrapper isMobile={isMobile}>
    <LoadingPlaceholder
      style={{ ...style }}
      width={240}
      height={24}
      {...rest}
    >
      <rect x="0" y="4" rx="0" ry="0" width="240" height="16" />
    </LoadingPlaceholder>
  </ProductOptionListItemPricingPlaceholderWrapper>
);

export default ProductOptionListItemPricingPlaceholder;
